.language-switcher {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  margin: 0;

  & li ~ li {
    margin-left: 1em;
  }
}

.language-select.active {
  color: $theme-active;
}
