/* * {
  outline: 1px solid red;
  outline-offset: 1px;
} */

::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

::-moz-selection { /* Code for Firefox */
  background: lightgray;
}

::selection {
  background: lightgray;
}

* {
  scrollbar-width: none;
}

input[type=checkbox]:checked, input[type=radio]:checked {
  filter: invert(1%) sepia(81%) saturate(23%) hue-rotate(88deg) brightness(97%) contrast(100%);
}

.grecaptcha-badge {
  visibility: hidden;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  /* make text crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  main {
    margin-top: 82px;
  }

  .container-max-width {
    padding: 0 5%;
    width: 100%;
    max-width: 1750px;
    margin: auto;
  }

  @media screen and (max-width: 1750px) {
    padding: 0;
  }

  p {
    a {
      color: #d1d10f;
    }

    a:hover {
      color: #d1d10f;
      text-decoration: underline;
    }
  }

  /**************/
  /*** HEADER ***/
  /**************/

  .header-content {
    /* center */
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .page-header {
    position: relative;
    /* margin-bottom: 15vh; */

    .background-wave {
      width: 100vw !important;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
    }


    .background-wave:not(.background-wave-homepage) {
      height: 120%;
    }

    .breadcrumbs {
      position: absolute; /* fixed */
      margin-top: 0 !important;
      padding-top: 65px !important;
      margin-left: 106px;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      z-index: 1;
      left: 0 !important;
    }

    @media screen and (max-width: 768px) {
      .breadcrumbs {
        margin-left: 70px !important;
        padding-top: 60px !important;
      }
    }

    .breadcrumb {
      display: flex;
      flex-wrap: wrap;
      padding: 0 !important;
      margin: 0 !important;
      list-style: none;
      background-color: unset !important;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px var(--unnamed-font-family-futura-pt);
    }

    .breadcrumb-item a:hover {
      text-decoration: none;
    }

    #breadcrumb {
      background-color: unset !important;
    }

    .teaser:not(.teaser-with-image2) {
      padding-top: 210px; /* 250px */
      /* padding-left: 5% !important; */
      padding-right: 5% !important;
      margin-top: -100px;
      padding-bottom: 80px; /* 120px */
    }

    .teaser {
      /* padding-bottom: 120px; */
      display: flex;
      flex-direction: row;
      /*justify-content: left;*/
      justify-content: center;
      /*padding-left: 10em;*/
      padding-top: 120px;
      width: 100%;
      overflow: hidden;

      .teaser-right {
        /* padding-right: 5vw; */
      }

      .teaser-left, .teaser-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        text-align: left;
      }

      .teaser-left {
        align-items: flex-start;
      }

      .teaser-right {
        width: 70%;
      }

      .teaser-left {
        margin-top: 30px;
        margin-top: 80px; /* 150px */
        width: 35%; /* 30% */
      }

      .teaser-middle {
        text-align: center;
      }

      .teaser-text > span {
        font: normal normal 500 34px/52px var(--unnamed-font-family-rokkitt);
        letter-spacing: 0;
        color: black;
        display: inline;
        background: $theme-background;
      }

      .teaser-title {
        font: var(--unnamed-font-style-normal) normal 900 35px var(--unnamed-font-family-futura-pt);
        line-height: 40px;
        letter-spacing: 0;
        color: black;
        padding-bottom: 1em;
        /*text-transform: uppercase;*/
      }

      .teaser-image {
        width: 90%;
        margin-bottom: 50px;
        height: auto;
      }

      .teaser-image-company {
        width: 90%;
      }

      .teaser-right-homepage {
        img {
          width: 80%;
        }
      }

      .teaser-image2 {
        margin-top: 25px;
        width: 65%;
        align-self: flex-start;
        transform: translateY(-80px);
        margin-bottom: -70px;

      }
    }

    .teaser:not(.teaser-with-image2) .teaser-left {
      margin-top: auto;
      margin-bottom: auto;
    }

    .slider-teaser-text {
      margin: 0
    }

    @media screen and (max-width: 996px) {
      .teaser:not(.teaser-with-image2) .teaser-left {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .teaser-right-services {
      .teaser-right {
        .teaser-image {
          width: 80%;
        }
      }

      .teaser-left {
        margin-block: auto;
        padding-right: 0 !important;

      }
    }

    .flex-centered {
      display: flex !important;
      justify-content: center !important;
      align-items: center;
    }

    @media screen and (min-width: 1920px) {
      .teaser {

        .teaser-text > span {
          font-size: 30px;
          line-height: 42px;
        }

        .teaser-left {
          .teaser-text > span {
            font-size: 30px;
            line-height: 42px;
          }
        }


      }
    }
    @media screen and (max-width: 1920px) {
      .teaser {
        padding-top: 120px;

        .teaser-text > span {
          font-size: 28px;
          line-height: 40px;
        }

        .teaser-left {
          /* padding-left: 0; */
          padding-right: 5%;

          .teaser-text > span {
            font-size: 28px;
            line-height: 40px;
          }
        }

        .teaser-title {
          font: var(--unnamed-font-style-normal) normal 900 35px var(--unnamed-font-family-futura-pt);
          line-height: 40px;
        }
      }
      .flex-right-invisible {
        display: none;
      }

    }
    @media screen and (max-width: 1440px) {
      .teaser {
        .teaser-text > span {
          font-size: 25px;
          line-height: 37px;
        }

        .teaser-left {

          .teaser-text > span {
            font-size: 25px;
            line-height: 37px;
          }
        }

        /* .teaser-left:not(.homepage-element) {
          margin-left: auto;
        } */

        .teaser-title {
          font: var(--unnamed-font-style-normal) normal 900 30px var(--unnamed-font-family-futura-pt);
          line-height: 35px;
        }
      }

    }
    @media screen and (max-width: 1200px) {
      .teaser {

        .teaser-text > span {
          font-size: 30px;
          line-height: 42px;
        }

        .teaser-title {
          font: var(--unnamed-font-style-normal) normal 900 30px var(--unnamed-font-family-futura-pt);
          line-height: 35px;
        }
      }
    }
    @media screen and (max-width: 996px) {
      .teaser {
        padding: 0 5%;
        padding-top: 120px; /* 80px */
        flex-direction: column-reverse; /* column */
        align-items: center;

        .teaser-right {
          margin: 0;
          padding: 0;
          width: 100% !important;
        }

        .teaser-image {
          margin: 20px 0 40px 0; /* margin-top: 50px */
          min-width: 100%;
          width: 100%;
        }

        .teaser-image2 {
          /* width: 60%; */
        }

        .teaser-text > span {
          font-size: 28px;
          line-height: 40px;
        }

        .teaser-left {
          margin: 0;
          min-height: 10vh;
          width: 100% !important;
          justify-content: flex-start;
          align-self: flex-start;
          align-items: flex-start;

          .teaser-text > span {
            font-size: 28px;
            line-height: 40px;
          }
        }


        .teaser-title {
          font: var(--unnamed-font-style-normal) normal 900 30px var(--unnamed-font-family-futura-pt);
          line-height: 35px;
        }
      }

      .teaser.homepage-teaser {
        flex-direction: column-reverse;
        padding-top: 200px;

        .teaser-image {
          margin-top: 0;
        }

        .teaser-left {
          margin-top: 50px;
          margin-bottom: 110px;
          height: 180px;
        }
      }

      .page-header {
        .teaser-right:not(.teaser-right-homepage) {
          min-width: 95%;

          .teaser-image {
            min-width: 100%;
            width: 100%;
          }
        }
      }

    }

    @media screen and (max-width: 768px) {
      .teaser {
        .teaser-right:not(.teaser-right-homepage) {
          .teaser-image {
            max-width: 100%;
          }
        }

        .homepage-teaser .teaser-left {
          margin-top: 15vh !important
        }

        .teaser:not(.teaser-with-image2) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }

    @media screen and (max-width: 550px) {
      .teaser {

        .teaser-image2 {
          width: 100%;
        }

        .teaser-text > span {
          font-size: 26px;
          line-height: 38px;
        }

        .teaser-left {
          .teaser-text > span {
            font-size: 26px;
            line-height: 38px;
          }
        }

      }
    }
    @media screen and (max-width: 400px) {
      .teaser {
        .teaser-title {
          font-size: 23px;
          line-height: 35px;
        }

        .teaser-text {
          > span {
            font-size: 23px;
            line-height: 35px;
          }
        }
      }
    }

    @media screen and (max-width: 350px) {
      .teaser {

        .teaser-text > span {
          font-size: 18px;
          line-height: 30px;
        }

        .teaser-title {
          font: var(--unnamed-font-style-normal) normal 900 20px var(--unnamed-font-family-futura-pt);
          line-height: 25px;
        }
      }
    }

    /* TEASER NOT HOMEPAGE */
    .page-header {
      .teaser-right:not(.teaser-right-homepage) {
        padding-right: 0;
      }
    }


    &.homepage {
      .teaser-title {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-35)/var(--unnamed-line-spacing-50) var(--unnamed-font-family-felttiproman);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-1d1d1b);
        text-align: left;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 250px;
      }
    }
  }
}

.faq_page-header-menu-a {
  color: white;
  transition: color 200ms ease-in-out;
}

.faq_page-header-menu-a:hover {
  color: black;
  text-decoration: none;
}

.blog-header-menu-a {
  color: white;
  transition: color 200ms ease-in-out;
}

.blog-header-menu-a:hover {
  color: black;
  text-decoration: none;
}

/***************/
/*** CONTENT ***/
/***************/

.row {
  /*height: 100%;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.row {
  .column-left, .column-right {
    width: 50%;
  }

  .column-right {
    padding-left: 5%;
  }
}

@media screen and (max-width: 996px) {
  .row {
    .column-left, .column-right {
      width: 100%;
    }

    .column-right {
      padding-left: 0;
    }
  }
}

@if $enable-grid-classes {
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}


@media screen and (max-width: 996px) {
  .row {
    flex-direction: column;

    &.ttags-company {
      flex-direction: row;
    }

    &.faq_page_root-1st_row {
      flex-direction: column-reverse;
    }

    &.blog_root-1st_row {
      flex-direction: column-reverse;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 80px; /* 100px */
    padding: 0;

    .column-right, .column-left {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    .column-right {
      width: 45%;
      margin-left: 10%;
    }

    .column-left {
      width: 40%;

      &.slider-service2-width {
        width: 41%;
      }
    }
  }

}

.not-homepage .column {
  width: 100%;
}


.image_video-image {
  width: 100%;
}

.image_video-image-img {
  width: 80%; /* 90% */
  margin-left: 5%;
  margin-bottom: 80px;
}

@media screen and (max-width: 1440px) {
  .image_video-image-img {
    width: 90%;
    min-width: 400px;
  }
}

@media screen and (max-width: 996px) {
  .image_video-image-img {
    margin-bottom: 30px;
  }
  .not-homepage .column {
    width: 100%;
  }

  .column {
    .row {
      flex-direction: column;

      .column-right {
        width: 100%;
        margin-left: 0;
      }

      .column-left {
        width: 100%;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .image_video-image-img {
    min-width: unset;
  }
}

@media (min-width: 1920px) {
  .image_video-image {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  .image_video-image-img {
    width: 75%;
  }
  .column-right {
    /* padding-right: 10%; */
  }
}

.content:not(.content-homepage) {
  padding-top: 80px; /* 120px */
  &.pt-200 {
    padding-top: 200px;
  }

  /* center */
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.content:not(.content-homepage) {
  .teaser {
    margin-bottom: 180px;
  }
}

.content-title-with-image {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 25px var(--unnamed-font-family-futura-pt);
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}

.content-description-with-image {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-family-futura-pt);
  font-size: 16px;
}

.content-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px var(--unnamed-font-family-futura-pt);
  font-size: 30px;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
  width: 100%;
}

.content-description {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

/* SOFTWARE GRID */

.software-grid-container {
  margin-top: 5px;
  width: 100%;
  height: auto;
}

.software-grid {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 80px;

  .grid-row {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }

  .grid-elem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .grid-elem-container {
      width: 80%;
      padding-top: 7%;
      padding-bottom: 23%;
      font-size: 18px;

      img {
        width: 50%;
      }
    }
  }

  .margin-top {
    margin-top: -15px;
    aspect-ratio: auto;
  }
}

.grid-elem-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 1.2em/1.4em var(--unnamed-font-family-futura-pt);
  color: white;

}

.grid-elem-teaser {
  font: var(--unnamed-font-style-normal) normal 900 1.2em/1.3em var(--unnamed-font-family-futura-pt);
  color: white;
}

#grid_elem1 {
  background: #0A94CF;
}

#grid_elem2 {
  background: #4A841C;
}

#grid_elem3 {
  background: #707070;
}

#grid_elem4 {
  background: #0071BC;
}

#grid_elem5 {
  background: #707070;
}

#grid_elem6 {
  background: #ADBB9C;
}

.software-grid:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}

.software-grid:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.software-grid:nth-child(3) {
  grid-area: 2 / 1 / 3 / 2;
}

.software-grid:nth-child(4) {
  grid-area: 2 / 2 / 3 / 3;
}

.software-grid:nth-child(5) {
  grid-area: 3 / 1 / 4 / 2;
}

.software-grid:nth-child(6) {
  grid-area: 3 / 2 / 4 / 3;
}

@media screen and (min-width: 1440px) {
  .content-title, .content-title-with-image {
    font-size: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .content-title, .content-title-with-image {
    font-size: 35px;
  }
  .software-grid {
    width: 100%;
  }
  .grid-elem-title {
    font-size: 1.4em;
    line-height: 1.8em;
  }
  .grid-elem-teaser {
    font-size: 1.4em;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 1440px) {
  .grid-elem-title {
    font-size: 1em;
    line-height: 1.4em;
  }
  .grid-elem-teaser {
    font-size: 1em;
    line-height: 1.1em;
  }
  .column {
    .content-title {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

/* for .content-title, .content-description */
.align-right {
  // font-size: 20px;
  width: 50%;
  text-align: left !important;
  align-self: flex-end;

  &.add-padding {
    padding-left: 5%;
  }
}

.content-title.align-right {
  // font-size: 20px; /* 22px */
  // line-height: 25px;
}

.technologies-logo-element {
  display: flex;
  /* height: calc((1.225em + 6vh + 1px) * 4 / 3); */
  aspect-ratio: 16/9;
  width: 25%;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  border: 1px dashed white; /* rgb(0, 0, 0, 0.10) */
  margin-right: -1px;
  margin-bottom: -1px;
  position: relative;
  z-index: unset;

  img {
    max-width: 90%;
    margin-left: 0; // 5%
    max-height: 100%;
    object-fit: scale-down;
  }
}

.link-ttags {
  &.ttags-company {
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 1.666%;
    padding: 30px;
    background: #eceef1;
    box-shadow: rgb(17 12 46 / 5%) 0px 48px 100px 0px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1200px) {
  .link-ttags {
    &.ttags-company {
      width: 50%;
    }
  }
}

@media screen and (max-width: 996px) {
  .link-ttags {
    &.ttags-company {
      width: 100%;
    }
  }

  .align-right {
    width: 100%;

    &.add-padding {
      padding-left: 0;
    }

    .software-grid {
      width: 90%;
    }

    .column {
      .row {
        margin-top: 0;
        flex-direction: column;

        .column-left, .column-right {
          width: 85%;
        }

        .column-left {
          margin-bottom: 30px;
        }
      }

      .service2_diagram-column-right {
        margin-left: 0;
      }

      .service2_root-name {
        transform: translateX(-5px);
        padding-left: 0;
      }

      .content-title-with-image, .content-description-with-image, .link-ttags {
        padding-left: 0;
      }
    }

  }

  @media screen and (max-width: 768px) {
    .column {
      .row {
        margin-top: 0;
        flex-direction: column;

        .column-left {
          .service2-teaser-image-2-bubble {
            height: auto;
            width: 120%;
            align-self: center;

            .service2-teaser-image-2-bubble-overlay {
              padding-top: 0;
              background-position: center 0;
              width: 105%;
              height: 105%;
              transform: translate(-3%, -4%);
            }

            .service2-teaser-image-2 {
              margin-top: 0;
            }
          }
        }
      }

      .content-description {
        margin-bottom: 30px;
        font-size: 16px;
      }

      .content-title {
        font-size: 25px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .software-grid {
      width: 90vw;
    }
    .grid-elem-title {
      font-size: 0.8em;
      line-height: 1.2em;
    }
    .grid-elem-teaser {
      font-size: 0.8em;
      line-height: 1em;
    }
  }

  @media screen and (max-width: 400px) {
    .grid-elem-title {
      font-size: 0.7em;
      line-height: 1.1em;
    }
    .grid-elem-teaser {
      font-size: 0.65em;
      line-height: 1em;
    }
  }

  .column-left {
    width: 50%;
  }

  .column-right {
    width: 50%;
  }

  .company-page .column-left {
    width: 55%;
  }

  .company-page .column-right {
    width: 45%;
  }

  .company-page {
    column-gap: 10%;

    .column-right {
      .company-image-container {
        transform: translate(-200px, -150px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .image_video-image {
    width: 90%;
  }

  .workflow-text, .link-ttags {
    padding-left: 10%;
  }

  @media screen and (max-width: 1440px) {
    .company-page .column-right {
      .company-image-container {
        transform: translate(-100px, -150px);
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .workflow-row {
    padding: 0;
    justify-content: flex-start;
  }
  .company-page .column-right {
    .company-image-container {
      transform: unset;
    }
  }
  .content-title-with-image, .content-title, .content-description-with-image, .content-description, .link-ttags {
    padding-left: 0;
  }
  .row {
    .column-right {
      padding-left: 0;

      .link-ttags, .workflow-text {
        padding-left: 0;
      }
    }
  }
}

@media screen and (min-width: 996px) {
  .link-ttags2 {
    margin-top: 107px;
  }
}

@media screen and (max-width: 768px) {
  .workflow-row {
    padding: 5%;
  }
}

.ttag_group-name, .workflow-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px var(--unnamed-font-family-futura-pt);
  font-weight: 600;
  text-decoration: none !important;

  &.ttags-company {
    font-size: 18px;
  }
}

.ttag_group-name:hover {
  /* TODO WHY THIS NEEDED */
  text-decoration: none;
}

.ttag_group-name-div, .workflow-title-div {
  padding-top: 10px;
  padding-bottom: 10px;

  &.ttags-company {
    text-align: center;
  }
}

.ttags-row {
  min-height: 350px;
}

.ttags {
  display: flex; /*TODO THIS CORRECT? */
  flex-wrap: wrap;

  &.ttags-company {
    justify-content: center;
  }
}

.ttag-with-image {
  width: calc(20% - 10px);
  background: rgba(0, 0, 0, 0.03);
  margin: 5px;
  min-height: 65px;
  min-width: 115px;
  display: flex;
  align-items: center;

  &.ttags-company {
    width: 20%;
    min-width: 60px;
  }
}

.ttag-img {
  width: 75%;
  margin-left: 12.5%;
  object-fit: contain;
}

@media screen and (max-width: 1750px) {
  .ttag-with-image {
    min-height: 55px;
    min-width: 100px;
  }
}

@media screen and (max-width: 370px) {
  .ttag-with-image {
    min-height: 50px;
    min-width: 75px;
  }
}

.link-faqs {
  width: 100%;
  padding-bottom: 85px;
}

.column-left .link-faqs {
  margin-left: 20px;
}

@media screen and (max-width: 996px) {
  .column-left .link-faqs {
    margin-left: 0;
  }
}

.faq-icon-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30px; /* 50px */
  padding: 15px 0;
  margin-top: 15px;
  /* padding-right: 75px; */
}

.faqs {
  width: 100%;
  column-gap: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.faqs-column-1 {
  display: flex;
  flex-direction: column;
  width: 100%; /* calc(50% - 20px) */
}

.faqs-full-width {
  width: 100%;
}

.faqs-column-2 {
  display: flex;
  flex-direction: column;
  width: 100%; /* calc(50% - 20px) */
}

.faq {
}

.faq-question {
  padding: 5px 0; /* 10px 0 */
  border-top: 1px solid darkgray;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.faq-question-text {
  color: #888;
  width: 90%;
  padding-left: 8px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 18px/20px;
  font-size: 16px;
  font-family: var(--unnamed-font-family-futura-pt);
  font-weight: 400;
  letter-spacing: var(--unnamed-character-spacing-0);
  cursor: pointer;
}

.faq-question-text:not(.thirdLevelNavContainer), .faq-question-text:not(.thirdLevelNavContainer_m) {
  color: #000;
}

.faq-question-text:hover {
  text-decoration: none;
}

.faq-question-icon {
  width: 10%;
  text-align: right;
  font-size: 1.5rem;
  padding-right: 5px;
  margin: auto;
}

.faq-question-icon:hover {
  text-decoration: none;
  cursor: pointer;
}

.faq-answer {
  margin-top: 13px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 15px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 18px/20px;
  // font-family: var(--unnamed-font-family-futura-pt);
  font-weight: 400; /* 300 */
  color: #000;
}


@media screen and (max-width: 996px) {
  .faqs {
    flex-direction: column;
  }
  .faqs-column-1, .faqs-column-2 {
    width: 100%;
  }
  .faqs-column-2 {
    transform: translateY(-1px);
  }
}

.ttag-without-image {
  padding-top: 7px; /* 18px */
}

.ttag-name {
  font-size: 16px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 6px 10px;
  font-family: var(--unnamed-font-family-futura-pt);
  margin-right: 8px;
  display: inline-block;
  word-break: break-word;
  text-align: left;
}

.ttag-name:hover {
  text-decoration: none;
}

/* .link-ttags3 {
  padding-top: 80px;
  padding-bottom: 80px;
} */

@media screen and (max-width: 996px) {
  #service2_whole_slider .column-left {
    width: 65%;
  }
}

@media screen and (max-width: 550px) {
  #service2_whole_slider .column-left {
    width: 80%;
  }
}

.service2_root-name {
  padding-left: 6%;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-30)/36px var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-transform: uppercase;
}

@media screen and (max-width: 1650px) {
  .service2_root-name {
    padding-left: 4%;
  }
}

@media screen and (max-width: 1440px) {
  .service2_root-name {
    padding-left: 2%;
  }
}

@media screen and (max-width: 1200px) {
  .service2_root-name {
    padding-left: 0;
  }
}

@media screen and (max-width: 996px) {
  .service2_root-name {
    margin-top: 75px;
    padding-left: calc(15% - 10px);
  }
}

.service2-teaser-image {
  width: 100%;
  height: 50%;
  position: absolute;
  z-index: 3;
  right: -4%;
  bottom: -5%;
  display: flex;
  justify-content: flex-end;
}

.service2-teaser-image-2-bubble-placeholder {
  height: 100%;
  position: relative;
}

.service2-teaser-image-2-bubble {
  height: 100%;
  position: relative;
  /*margin-top: 100px;*/
}

.service2-teaser-image-2-bubble-overlay-svg, .solution-teaser-image-2-bubble-overlay-svg {
  width: 102%;
  left: -1%;
  position: absolute;
  margin-top: -3%;
  z-index: 1 !important;
}

.solution-teaser-image-2-bubble-overlay-svg {
  transform: scale(1.1, 0.95);
}

.service2-teaser-image-2 {
  height: auto; /*magic*/
  transform: scale(1.3);
}

@media screen and (max-width: 768px) {
  .service2-teaser-image-2 {
    transform: scale(1);
  }
}

.service2-teaser-image-2-img {
  width: 100%;
}

.service2-teaser-image-alone {
  position: relative;
  width: 100%;

  .service2-teaser-image-img {
    width: 100%;
  }
}

.service2_diagram-column-right {
  max-height: 330px;
  margin-left: 15%;
  margin-right: 5%;
  padding-left: 50px;
  border-left: dashed 1px;
  /* border-left: 1px dotted black; */
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 75px;
}

.service2_parent {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.service2_diagram-dot {
  /*height: 20px;
  width: 20px;*/
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-left: -60px;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.active {
    transform-origin: center;
    transform: scale(1.1);
    background-color: var(--unnamed-color-d1d10f);
  }
}

.service2_diagram-diagonal-line {
  width: 28px;
  margin-top: 10px;
  height: calc(100% + 35px);
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-dasharray="4, 1.5" stroke-width="0.5" shape-rendering="auto"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
  margin-left: -50px;
}

.service2-right {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

.service2_parent-name {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-25)/30px var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.active {
    color: var(--unnamed-color-d1d10f);
  }
}

.service2_parent-name:hover {
  text-decoration: none;
  color: var(--unnamed-color-d1d10f);
  cursor: pointer;
}

/* .service2_parent-teaserTitle {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in;
} */

.service2_parent-teaserTitle {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
}

.service2_parent-teaserTitle:hover {
  text-decoration: none;
}

.service2_child {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.service2_child_diagram-dot {
  /* change background-color and margin-left to get parent dot */
  width: 16px;
  height: 16px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-left: -31px;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.active {
    transform: scale(1.1);
    background-color: #c6db94;
  }

}

.service2_child_diagram-diagonal-line {
  /* change x1 to get parent line */
  width: 28px;
  margin-top: 10px;
  height: calc(100% + 20px); /* 35px */
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="100%" y1="0" x2="100%" y2="100%" stroke="black" stroke-dasharray="3.5, 1.5" stroke-width="2" shape-rendering="auto"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
  margin-left: -50px;
}

.service2_child-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.service2_child-name {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-25)/30px var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);

  &.active {
    color: var(--unnamed-color-d1d10f);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.service2_child-name:hover {
  text-decoration: none;
  /* color: var(--unnamed-color-7fa722); */
  cursor: pointer;
}

/* .service2_child-teaserTitle {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in;
} */

.service2_child-teaserTitle {
  font-size: 16px;
}

.service2_child-teaserTitle:hover {
  text-decoration: none;
}

@media screen and (max-width: 1440px) {
  .service2-right {
    font-size: 17px;
    margin-left: 10px;
  }
  .service2_root-name {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  /* .service2 {
    margin-top: 80px;
    margin-bottom: 80px;
  } */
  .service2-right {
    font-size: 15px;
  }

}

/* SOLUTION TEASER-CARDS */
.solution-teaser {
  width: calc(25% - 20px);
  background-color: #eceef1;
  padding: 35px 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 996px) {
  .solution-teaser {
    width: 100%;
    margin-right: 0;
  }
}

.solution-teaser-title {
  /* font-weight: bold; */
  font-size: 16px;
  padding-bottom: 15px;
}

.solution-teaser-image {
  img {
    width: 100%;
    margin-bottom: 35px;
  }
}

.solution-teaser-name {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  /* padding-top: 15px; */
  cursor: pointer;
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 10%;
  transition: color 200ms ease-in-out;
}

.solution-teaser-name:hover {
  color: #D1D10F;
}

/* SOLUTION PAGE TEASER-CARDS */
.addedvalue-overview-cards {
  margin-bottom: 80px;
  flex-wrap: wrap;
}

.page-teaser-cards {
  width: calc(25% - 20px); /* 33.333% */
  background-color: #eceef1;
  padding: 35px 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.page-teaser-cards.active_page {
  opacity: 0.5;
}

@media screen and (max-width: 1440px) {
  .addedvalue-overview-cards {
    flex-wrap: wrap;

    .page-teaser-cards {
      width: 33.333%;
    }
  }
}

@media screen and (max-width: 996px) {
  .page-teaser-cards {
    width: 100% !important;
    margin-right: 0;

  }
}

.page-teaser-card-title {
  /* font-weight: bold; */
  padding-bottom: 15px;
}

.page-teaser-card-image {
  /* margin-bottom: 35px; */

  img {
    width: 100%;
    margin-bottom: 40px;
  }
}

.page-teaser-card-name {
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  padding-top: 15px;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  position: absolute;
  bottom: 10%;
  width: 90%;
  left: 5%;
}

.page-teaser-card-name:not(.active_page):hover {
  color: #D1D10F;
}

.contact-form-background {
  /*height: 50px;*/
  background-color: #eceef1;
  width: 100vw;
  z-index: 2;
  position: sticky;
  bottom: 0px;
}

.contact-form {
  width: 100%;
  height: 100%;
  position: relative;
  padding-right: 10%;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
}

// custom radio button
.form-check {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  padding: 0;
}

.form-check input[type='radio'] {
  display: none;
}

.form-check label {
  color: #000;
  font-weight: normal;
}

.showcase-form .form-check label {
  color: #fff;
}

.form-check label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #d1d10f;
  background-color: transparent;
}

.form-check input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #d1d10f;
}

.showcase .form-check input[type=radio]:checked + label:after {
  background: #fff;
}

.showcase .form-check label:before {
  border: 2px solid #fff;
}

#submitBtn {
  background-color: #D1D10F !important;
  border: 0 !important;
}

#submitBtn:hover {
  background-color: #BCC400 !important;
  border: 0 !important;
}

.showcase #submitBtn {
  background-color: #fff !important;
  color: #000;
}

.showcase #submitBtn:hover {
  background-color: #e1e1e1 !important;
}

.form-control:checked:before {
  background-color: #7fa722 !important;
}

.contact-form-opener {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#contact-form-opener-icon {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 996px) {
  #contact-form-opener-icon {
    width: 14px;
    height: 14px;
  }
}

.contact-form-opener-text {
  width: 100%;
  text-align: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-35) var(--unnamed-font-family-futura-pt);
  font-weight: bold;
  margin-top: 15px;
  font-size: 25px;
  transition: color 300ms ease-in-out;
}

.contact-form-opener-text:hover {
  /* color: #D1D10F; */
}

.contact-form-opener-btn {
  transform-origin: 50% 35%;
  margin-top: 18px;
  font-weight: bold;
  font-size: 22px;
  margin-left: 20px; /* min space to text */
  transition: transform 150ms ease-in-out;

  svg path {
    transition: fill 300ms ease-in-out;
  }
}

.contact-form-opener-btn:hover {
  text-decoration: none;
  cursor: pointer;

  svg path {
    fill: #D1D10F;
  }
}


.contact-form-description {
  text-align: center;
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
}

.contact-form-description-a {
  font: normal normal 500 25px/38px var(--unnamed-font-family-rokkitt);
  letter-spacing: 0;
  color: black;
  display: inline;
  background: #fff;
}

.contact-form-description-a:hover {
  text-decoration: none;
}

.contact-form-row {
  display: flex;
  flex-direction: row;
}

.contact-form-column-left {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.contact-form-column-right {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 960px;
}

.form-control {
  border-radius: 3px;
  font-family: var(--unnamed-font-family-futura-pt);
  height: 40px;
  padding-top: 9px;
}

.required-label {
  font-family: var(--unnamed-font-family-futura-pt);
  color: #949494;
}

.data-protection-a {
  text-decoration: underline;
}

.data-protection-a:hover {
  cursor: pointer;
}

.contact-form-bubble {
  margin-left: 20%;
  position: relative;
}

.contact-form-bubble-overlay {
  position: absolute;
  height: 101%;
  width: calc(101% + 6px);
  margin-top: -3px;
  margin-left: -3px;
  background-image: url('data:image/svg+xml;utf8, <svg id="uuid-c755f6cd-e26f-4545-822f-790d616714cd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 721.06"><defs><style>.uuid-d35c2692-069f-45b4-bdba-8590f219cf7e{fill:#eceef1;}</style></defs><path class="uuid-d35c2692-069f-45b4-bdba-8590f219cf7e" d="M2.42,0V721.06H995.58V0H2.42ZM717,526.79c-93.32,120.97-242.42,192.39-345.54,169.74-98.98-21.76-140.51-172.13-161.17-246.95-14.32-51.84-52.42-189.82,8.38-239.78,46.98-38.6,99.88,19,186.46-11.32,109.19-38.24,103.72-157.35,191.15-175.8,75.97-16.04,175.9,53.68,203.73,137.12,32.03,96.01,9.21,247.44-83.01,367Z"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.contact-form-bubble-overlay-2 {
  position: absolute;
  width: 10%;
  margin-left: -10%;
  height: 100%;
  background-color: #eceef1;
}

.contact-form-bubble-overlay.contact-page {
  background-image: url('data:image/svg+xml;utf8, <svg id="uuid-c755f6cd-e26f-4545-822f-790d616714cd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 721.06"><defs><style>.uuid-d35c2692-069f-45b4-bdba-8590f219cf7e{fill:white;}</style></defs><path class="uuid-d35c2692-069f-45b4-bdba-8590f219cf7e" d="M2.42,0V721.06H995.58V0H2.42ZM717,526.79c-93.32,120.97-242.42,192.39-345.54,169.74-98.98-21.76-140.51-172.13-161.17-246.95-14.32-51.84-52.42-189.82,8.38-239.78,46.98-38.6,99.88,19,186.46-11.32,109.19-38.24,103.72-157.35,191.15-175.8,75.97-16.04,175.9,53.68,203.73,137.12,32.03,96.01,9.21,247.44-83.01,367Z"/></svg>');
}

.contact-form-bubble-overlay-2.contact-page {
  background-color: white;
}


@media screen and (max-width: 1350px) {
  .contact-form-row {
    flex-direction: column;
  }
  .contact-form-column-left {
    width: 100%;
  }
  .contact-form-column-right {
    width: 100%;
    margin-top: 50px;
    align-items: center;
  }
  .contact-form-bubble {
    margin-left: 0;
    width: 65%;
  }
}

@media screen and (max-width: 996px) {
  .contact-form {
    padding-left: 2em;
    padding-right: 2em;
  }
  .contact-form-opener-text {
    font-size: 18px;
  }
  .contact-form-opener-btn {
    margin-top: 12px;
  }
  .contact-form-bubble {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 620px) {
  .contact-form-bubble-overlay, .contact-form-bubble-overlay-2 {
    display: none;
  }
  .contact-form-bubble {
    width: 100%;
  }
  #map {
    height: 300px;
  }
}

@media screen and (max-width: 550px) {
  .contact-form-opener-text {
    font-size: 15px;
  }
}

/* TODO FIX */

/* SERVICE */
.service-teaser {
  width: 100%;
}

.service-teaser-cards {
  justify-content: center;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  max-height: 1500px;
  align-items: center;
  max-width: calc(1200px + 4em);
  margin: 0 auto;

  background: transparent radial-gradient(closest-side at 50% 50%, var(--unnamed-color-ffffff) 0%, #E6E6E6 56%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;
}

.service-teaser-card {
  max-width: 400px;
  margin: 10px;
  margin-bottom: 1em;
  border-radius: 4px;

  &:not(.no-background) {
    background: white;
    box-shadow: 0 2px 4px #00000029;
  }


  &:nth-child(1), &:nth-child(3) {
    margin-top: 80px;
  }

  /*&:nth-child(5), &:nth-child(4){
    margin-top: -70px;
  }
  &:nth-child(8), &:nth-child(7){
    margin-top: -140px;
  }*/
}

.service-teaser-card img {
  width: 100%;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

.service-teaser-card-title {
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
  font-size: 20px;
}

.service-teaser-card-text {
  font-family: var(--unnamed-font-family-futura-pt);
  font-size: 18px;
  padding: 7px;
}

.service2_child-teaser-name {
  font-size: 25px;
}

.service2_child-teaser {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .service-teaser-cards {
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    row-gap: 20px;
    max-height: fit-content;

    .service-teaser-card {
      margin: 0;
      margin-right: 1em;
    }

    .service-teaser-card:nth-child(1), .service-teaser-card:nth-child(7) {
      margin-top: 0;
    }
  }

}

@media screen and (max-width: 996px) {
  .service-teaser {
    margin-top: 80px;
  }
  .service-teaser-cards {

    background: transparent radial-gradient(closest-side at 50% 35%, var(--unnamed-color-ffffff) 0%, #E6E6E6 16%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;

    .service-teaser-card:nth-child(1) {
      margin-top: 0;
    }

    .service-teaser-card, .service-teaser-card:nth-child(7) {
      margin-top: 2em;
      margin-right: 1em;
      margin-left: 1em;
    }

  }
}

@media screen and (max-width: 996px) {
  .service-teaser-cards {
    max-width: 100%;
  }
}

/* COMPANY */

.description-text-left {
  pre {
    overflow: visible;
    white-space: normal;

    span {
      width: 100%;
    }
  }
}

.company_child-teasers {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.company_child-teaser {
  width: 373px; /* 33.333% - 20px */
  background-color: #eceef1;
  padding: 35px 30px;
  margin-right: 20px;
  margin-bottom: 80px;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 996px) {
  .company_child-teasers {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .company_child-teaser {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.company_child-teaser-title {
  /* font-weight: bold; */
  padding-bottom: 15px;
}

.company_child-teaser-image {
  margin-bottom: 35px;
}

.company_child-teaser-image-img {
  width: 100%;
  margin-bottom: 40px;
}

.company_child-teaser-name {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 10%;
  transition: color 200ms ease-in-out;
}

.company_child-teaser-name:hover {
  color: #D1D10F;
}


.employee-teaser {
  width: calc(33.333% - 30px);
  border-radius: 4px;
}

.employee-teasers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 45px;
  width: 100%;
  row-gap: 20px;
  margin-bottom: 120px;
}

@media screen and (max-width: 1440px) {
  .employee-teaser {
    width: calc(50% - 30px);
  }
}

@media screen and (max-width: 996px) {
  .employee-teasers {
    flex-direction: column;
  }
  .employee-teaser {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.employee-teaser-image {
  height: 250px;
}

.employee-teaser-image img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 250px;
  object-fit: cover;
}

.employee-teaser-text {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  position: relative;
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px #00000029;
}

.employee-teaser-name {
  font-weight: bold;
}

.employee-teaser-name:hover {
  text-decoration: none;
}

.employee-teaser-job_title {
  font-weight: bold;
}

.employee-teaser-job_title:hover {
  text-decoration: none;
}

.employee-teaser-comment {
  /* position: absolute;
  bottom: 0; */
}

.employee-teaser-comment:hover {
  text-decoration: none;
}

.gallery-title {
  text-transform: uppercase;
  word-spacing: 2px;
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  font-weight: bolder;
  padding-bottom: 3px;
}

.gallery-img, #galleryVideo {
  height: 60vw;
  object-fit: cover;
  object-position: center center;
}

@media screen and (min-width: 768px) {
  .gallery-img, #galleryVideo {
    height: 400px;
  }
}

.slick-initialized {
  overflow: hidden;
}

.slick-slide {
  margin: 0 20px;
  transition: all 200ms ease;
  opacity: 1;
  /* filter: blur(1px); */
}

.slick-slide.slick-current.slick-active {
  filter: blur(0px);
}

.slick-list {
  max-width: 1440px;
  margin: 0 -20px;
}

/*
.gallery {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 21px;
  row-gap: 21px;
  margin-bottom: 50px;
  .close-gallery {
    position: fixed;
    width: 25px;
    aspect-ratio: 1/1;
    top: calc(15% + 5px);
    right: calc(10% + 5px);
    z-index: 4;
    img {
      filter: brightness(0.5);
    }
  }
}

.big-gallery-img {
  display: none;
}
.gallery-img, .big-gallery-img {
  width: calc(33.333% - 14px);
  object-fit: scale-down;

  /* &:nth-child(4) {
    margin-left: 10.5%;
    width: calc(26.333% - 14px);
  }

  &:nth-child(5) {
    width: calc(26.333% - 14px);
  }

  &:nth-child(6) {
    margin-right: 10.5%;
    width: calc(26.333% - 14px);
  } */
/* &.open {
  display: unset;
  position: fixed;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  box-shadow: 0px 0px 100px 800px rgba(0,0,0,0.7);
}
}


@media screen and (max-width: 768px) {
.gallery-img {
  width: calc(50% - 14px);
  &.open {
    top: 25%;
  }
}
.gallery {
  .close-gallery {
    top: calc(25% + 5px);
  }
}
}

.works-gallery {
position: relative;
overflow: hidden;
width: calc(33.333% - 14px);

&:nth-child(4) {
  width: calc(66.666% - 7px);
}

&:nth-child(5) {
  width: calc(33.333% - 14px);
}
}

.big-works-gallery-img {
display: none;
}
.works-gallery-img, .big-works-gallery-img {
width: 100%;
height: 100%;
object-fit: cover;
transition: transform 300ms ease-in-out;
&.open {
  display: unset;
  position: fixed;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 3;
  box-shadow: 0px 0px 100px 800px rgba(0,0,0,0.7);
}
}

.works-gallery-img-overlay {
width: 100%;
height: 100%;
background: rgb(255, 255, 255);
opacity: 0.0;
z-index: 1;
position: absolute;
top: 0;
left: 0;
transition: visibility 300ms ease-in-out, opacity 200ms ease-in-out;
}

.works-gallery-img-overlay-title {
top: 45%;
left: 20%;
color: #000;
font-size: 35px;
font-weight: bold;
opacity: 1;
z-index: 1;
position: absolute;
transition: opacity 300ms ease-in-out;
}

.works-gallery-img-overlay-title_sm {
top: 30%;
left: 20%;
color: #000;
font-size: 22px;
opacity: 1;
z-index: 1;
position: absolute;
transition: opacity 300ms ease-in-out;
}

@media screen and (max-width: 1440px) {
.works-gallery-img-overlay-title {
  font-size: 25px;
}
.works-gallery-img-overlay-title_sm {
  font-size: 14px;
}
}

@media screen and (max-width: 768px) {
.works-gallery-img-overlay-title {
  font-size: 20px;
}
.works-gallery-img-overlay-title_sm {
  font-size: 12px;
}
}

@media screen and (max-width: 450px) {
.works-gallery-img-overlay-title {
  font-size: 12px;
  line-height: 11px;
}
.works-gallery-img-overlay-title_sm {
  font-size: 8px;
  line-height: 5px;
}
} */

.company-content-blocks {
  div {
    padding-right: 0; /* 35px */
  }
}

.content-block-wrapper {
  padding-right: 0;
}

@media screen and (max-width: 450px) {
  .company-content-blocks {
    div {
      padding-right: 0;
    }

    i {
      right: -30px;
    }
  }
  .content-block-wrapper {
    padding-right: 0;
  }
}

@media screen and (max-width: 350px) {
  .company-content-blocks {
    i {
      bottom: 6px;
    }
  }
}

.customers {
  width: 50%; /* 60% */
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
}

.customer {
  width: calc(33.333% - 16px);
  padding-bottom: 20px;
}

.customer-img {
  width: 100%;
  transition: all 300ms ease-in-out;
  pointer-events: none;
}

.customer-img-container {
  pointer-events: auto;
  transition: all 300ms ease-in-out;
  filter: grayscale(100%);
}

.customer-img-container:hover {
  pointer-events: auto;
  filter: grayscale(0%);
}

.customer_root-text {
  font-size: 20px;
  width: 50%;
  padding-left: 5%;
  margin-bottom: 120px;
}

.customer_root-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-25)/30px var(--unnamed-font-family-futura-pt);
  font-size: 22px;
  line-height: 27px;
}

.customer_root-quote {
  font-size: 16px;
}

.customer_root-quote-autor {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 5px;
}

.customer_root-title:not(.customer_root-title:first-child) {
  margin-top: 50px;
}

@media screen and (max-width: 1440px) {
  .customer-index {
    .customer_root-text {
      font-size: 20px;

    }
  }
}


@media screen and (max-width: 996px) {
  .customer-index {
    .row {
      flex-direction: column;

      .customers, .customer_root-text {
        padding: 0;
        width: 100%;
        align-self: center;
      }

      .customer_root-text {
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
  }

  .customers, .customer_root-text {
    padding: 0;
    width: 100%;
    align-self: center;
  }

  .customer_root-text {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .column-left, .column-right {
    width: 100%;
  }
  .company-page .column-left, .company-page .column-right {
    width: 100%;
  }
  .company-page .column-left {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .customer-index {
    .customer_root-text {
      font-size: 17px;
    }
  }

}

.faqs-list {
  /* margin-top: 30px; */
  width: 100%;
  background-color: white;
  padding: 15px;
  min-height: 600px;
}

@media screen and (max-width: 996px) {
  .faqs-list {
    margin-top: 40px;
  }
}

.faq-icon-img2 {
  width: 50px;
  padding-right: 0;
  position: absolute;
  margin-left: calc(50% - 32.5px);
  margin-top: -41px
}

.faq-sidebar {
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* padding-top: 100px;
  padding-left: 10px;
  padding-right: 10px */
}

.faq-tags {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;

  a {
    padding: 5px 15px;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 15px;
  }
}

@media screen and (max-width: 996px) {
  .faq-tags {
    a {
      padding: 3px 8px;
      border: 1px solid black;
      border-radius: 3px;
      font-size: 12px;
    }
  }
}

.faq-tag_groups {
  padding-top: 10px;
  color: #707070;
  width: 100%;

  div {
    padding: 15px 20px;
    border: 1px solid #dbdddf;
    background-color: #eceef1;
    width: 100%
  }
}

.faq-image-and-description {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 10px #00000029; /*  box-shadow: 0 4px 4px #00000029;*/
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 25px;
  padding-bottom: 10px;

  img {
    width: 33.333%;
    margin-left: 33.333%;
    margin-top: 25px;
    margin-bottom: 25px
  }
}

.blogs-list {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
}

.blog {
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px #00000029;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /*background-color: #141A21CE;*/
  }
}

.blog_root-1st_row {
  column-gap: 30px;
  margin-bottom: 80px;
  margin-top: 50px;
}

.faq_page_root-1st_row {
  column-gap: 30px;
  margin-bottom: 250px;
}

@media(max-width: 1200px) {
  .blog {
    width: 100% !important;
  }
}

@media(max-width: 996px) {
  .blogs-list {
    margin-top: 40px;
  }
  .blog-list-column-right {
    display: none;
  }
  .faq-list-column-right {
    display: none;
  }
  .blog-list-column-left {
    width: 100% !important;
  }
  .faq-list-column-left {
    width: 100% !important;
  }
  .blog_root-1st_row {
    margin-top: 70px;
  }
  .faq_page_root-1st_row {
    /* margin-top: 70px; */
  }
  .blog-icon-img {
    display: none;
  }
  .faq-icon-img2 {
    display: none;
  }
  .faq-sidebar {
    /* margin-top: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px; */
  }
  .faq-tags {
    padding-top: 13px;
  }
  .faq-image-and-description {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

.blog-list-sidebar-opener-for-mobile {
  position: absolute;
  right: 0;
  top: 68px;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 20px;
  padding-left: 12px;
  padding-bottom: 10px;
  padding-right: 43px;
  cursor: pointer;
}

.faq-list-sidebar-opener-for-mobile {
  position: absolute;
  right: 0;
  top: 68px;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 20px;
  padding-left: 12px;
  padding-bottom: 10px;
  padding-right: 43px;
  cursor: pointer;
}

@media(min-width: 996px) {
  .blog-list-sidebar-opener-for-mobile {
    display: none;
  }
  .faq-list-sidebar-opener-for-mobile {
    display: none;
  }
}

.blog-icon-img-for-opener-for-mobile {
  width: 80px;
}

.faq-icon-img-for-opener-for-mobile {
  width: 35px;
  transform: translate(15px, -5px);
}

.blog-list-column-right-for-mobile {
  width: 100% !important;
}

.faq-list-column-right-for-mobile {
  width: 100% !important;
}

.blog-container {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.blog-teaser-texts {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 17px;
  padding-bottom: 28px;
  width: 75%;
}

.blog-teaser-title {
  font-weight: bold;
  width: 70%;
  padding-right: 5%;
}

.blog-teaser-text {
  font-size: 15px;
}

.blog-teaser-top-line {
  width: 100%;
  display: flex;
}

.blog-teaser-bottom-line {
  width: 100%;
}

.blog-teaser-tags {
  width: 25%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row-reverse;
  column-gap: 10px;

  a {
    padding: 4px 15px;
    border: 1px solid black;
    border-radius: 3px;
    text-align: center;
    font-size: 15px;
    height: fit-content;
    cursor: pointer;
    text-decoration: none;
  }
}

@media screen and (max-width: 550px) {
  .blog-teaser-top-line {
    flex-direction: column-reverse;
  }
  .blog-teaser-tags {
    margin-left: 0;
    flex-flow: row;
    margin-bottom: 25px;
  }
}

.blog-icon-img {
  display: none;
  width: 100px;
  padding-right: 0;
  position: absolute;
  margin-left: calc(50% - 50px);
  margin-top: -33px
}

/* @media screen and (min-width: 1600px) {
  :not(.homepage).column {
    max-width: 80%;
  }
} */

/* SERVICES */

/* TEASER */
.teaser-center {
  text-align: center;
}

.teaser-services {
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;

  .teaser-image2 {
    margin-top: 50px;
    width: 50% !important;
  }
}


@media screen and (max-width: 996px) {
  .teaser-services {
    flex-direction: column-reverse;

    .teaser-image2 {
      margin-top: 120px;
      width: 70% !important;
      margin-left: 15%;
    }
  }
}

@media screen and (max-width: 550px) {
  .teaser-services {
    flex-direction: column-reverse;

    .teaser-image2 {
      width: 90% !important;
      margin-left: 5%;
    }
  }
}

/* CONTENT */
.accordion-text-frontend {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  /* column-gap: calc(10%); /* 95px */

  .column-left {
    margin-right: 20px;
  }

  .column-left, .column-right {
    width: 50%;
  }

  .column-right {
    padding-left: 0;
  }

  .column-content-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 21px var(--unnamed-font-family-futura-pt);
    width: 100%;
    padding-top: 18px;
  }

  .faqs-column-1 {
    /* margin-left: 20%; */
    width: 100%;
  }
}

.accordion-text-frontend > * + * {
  margin-left: 10%;
}

@media screen and (max-width: 996px) {
  .accordion-text-frontend > * + * {
    margin-left: 0;
  }
}

.service2_children-teasers {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* column-gap: calc(10%); /* 95px */
  margin-bottom: 80px;

  .column-left {
    margin-right: 20px;
  }

  .column-right {
    padding-left: 0;
  }
}

.service2_children-teasers > * + * {
  margin-left: 10%;
}

@media screen and (max-width: 996px) {
  .service2_children-teasers > * + * {
    margin-left: 0;
  }
}


.service2_child-teaser {
  width: 50%;
}

@media screen and (min-width: 1690px) {
  .accordion-text-frontend .faqs {
    width: 100%;
    max-width: 800px;
    justify-content: flex-end;
  }
  .accordion-text-frontend .faqs-column-1 {
    margin-left: 0;
  }
  .accordion-text-frontend .column-content-text {
    width: 100%;
  }
  .service2_children-teasers div:first-child {
    /* margin-left: 50px; */
  }
  .service2_children-teasers div:nth-child(2) {
    /* transform: translateX(-25px); */
  }
}

@media screen and (max-width: 996px) {
  .service2_children-teasers {
    margin-bottom: 30px;
  }
  .accordion-text-frontend {
    flex-direction: column;

    .faqs-column-1 {
      margin-left: 0;
      width: 100%;
    }

    .column-left {
      width: 100%;
    }

    .column-right {
      width: 100%;
      margin-left: 0;
    }

    .column-content-text {
      padding-right: 0;
    }
  }
  .service2_children-teasers {
    flex-direction: column;
    row-gap: 20px;
    margin-left: 0;
  }
  .service2_child-teaser {
    width: 100%;
  }
}

/* HOMEPAGE TEASER BUBBLE TEXT */
.teaser-bubble-text {
  .top-left, .bottom-left, .bottom-right {
    position: absolute;
    z-index: 2;

    p {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-felttiproman);
      font-size: 18px;
      margin: 0;
      /* margin-bottom: 5px; */
      padding: 0;
    }

    p:hover {
      cursor: pointer;
    }
  }

  .top-left {
    top: 10%;
    left: 6%;

  }

  .top-left p:nth-child(1) {
    transform: translateX(65px);
  }

  .top-left p:nth-child(2) {
    transform: translateX(30px);
  }

  .bottom-left {
    bottom: 22%;
    left: 2%;
  }

  .bottom-left p:nth-child(2) {
    transform: translateX(55px);
  }

  .bottom-right {
    right: -2%;
    bottom: 15%;

    p:nth-child(2) {
      transform: translateX(10px);
    }

    p:nth-child(3) {
      transform: translateX(40px);
    }

    p:nth-child(4) {
      transform: translateX(10px);
    }

    p:nth-child(5) {
      transform: translateX(-5px);
    }
  }

  .middle {
    z-index: 2;

    p {
      position: absolute;
      margin: 0;
      padding: 0;
      top: 42%;
      left: 36%;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px/45px var(--unnamed-font-family-felttiproman);
    }
  }
}

@media screen and (min-width: 1750px) {
  .teaser-bubble-text .middle p {
    margin: 0;
    padding: 0;
    top: 40%;
    font-size: 35px;
    line-height: 45px;
  }
}

@media screen and (max-width: 1600px) {
  .teaser-bubble-text .middle p {
    margin: 0;
    padding: 0;
    top: 39%;
    font-size: 25px;
    line-height: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .teaser-bubble-text .middle p {
    margin-top: 5px;
    font-size: 18px;
    line-height: 25px;
  }
  .teaser-bubble-text .top-left p, .teaser-bubble-text .bottom-left p, .teaser-bubble-text .bottom-right p {
    font-size: 14px;
  }

  .teaser-bubble-text {
    .bottom-left {
      left: -2%;
    }

    .bottom-right {
      bottom: 12%;
    }
  }
}

@media screen and (max-width: 996px) {
  .teaser-bubble-text .middle p {
    font-size: 28px;
    line-height: 40px;
  }
  .teaser-bubble-text {
    .bottom-right {
      right: -4%;
      bottom: 16%;
    }

    .bottom-left {
      bottom: 23%;
      left: -4%;
    }

    .top-left {
      top: 25%;
      left: -2%;
    }

    .top-left, .bottom-left, .bottom-right {
      p {
        font-size: 16px;
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .teaser-bubble-text {
    .middle p {
      font-size: 22px;
      line-height: 35px;
    }

    .top-left, .bottom-left, .bottom-right {
      display: none;
    }
  }
}


@media screen and (max-width: 550px) {

  .teaser-bubble-text {
    .middle p {
      top: 37%;
      font-size: 16px;
      line-height: 25px;
    }

  }
}

@media screen and (max-width: 470px) {
  .teaser-bubble-text {
    .middle p {
      margin-left: -5px;
      font-size: 14px;
      line-height: 20px;
    }
  }

}

@media screen and (max-width: 350px) {
  .teaser-bubble-text {
    .middle p {
      font-size: 12px;
      line-height: 15px;
    }
  }
}


/**************/
/*** FOOTER ***/
/**************/

footer {
  margin-top: auto;
  width: 100vw;
}

/**************/
/*** GLOBAL ***/
/**************/

@media screen and (min-width: 1750px) {
  .teaser-container {
    margin-top: 50px;
  }
  .page-header .teaser {
    padding-left: 0 !important;

    .teaser-right {
      width: 55% !important; /* 65% */
    }

    .teaser-right-homepage {
      display: flex !important;
      justify-content: center !important;
    }

    .teaser-left {
      padding-left: 0 !important;

      .teaser-title {
        font: var(--unnamed-font-style-normal) normal 900 30px var(--unnamed-font-family-futura-pt);
        line-height: 35px;
      }
    }
  }
  .page-header .teaser-right-services {
    .teaser-right {
      width: 60% !important;
    }

    .teaser-left {
      margin-right: 5%;
    }
  }
}

.teaser-right .teaser-image-app {
  padding-top: 50px;
}

@media screen and (min-width: 1850px) {
  body .container-max-width {
    padding: 0 0;
  }
}

@media screen and (max-width: 1750px) {
  .teaser-right:not(.teaser-right-homepage) {
    width: 60% !important;
  }
  .teaser-left {
    width: 40% !important;
  }
  .teaser .teaser-image:not(.teaser-image-homepage) {
    /* padding-top: 150px; */
  }
  .teaser .teaser-image-company {
    /* padding-top: 50px !important; */
  }
  .page-header .teaser-right-services {
    .teaser-image {
      padding-top: 0;
    }

    .teaser-left .teaser-container {
      padding-top: 50px;
    }
  }
}

@media screen and (max-width: 996px) {
  .teaser .teaser-image {
    padding-top: 0;
  }
  .teaser .teaser-image:not(.teaser-image-homepage) {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  body .container-max-width {
    padding: 0;
  }
  .bottom-line {
    padding: 0 5%;
  }

}

.ct_text_editor {
  font-size: 14px;
}

.faq-image-and-description a {
  text-decoration-color: #d1d10f;
}

/* WORKFLOW STYLES */


.progressbar-container {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  margin: 0;
  width: auto;
  margin-top: 50px;
  height: auto;
}

@media screen and (max-width: 1550px) {
  .progressbar-container {
    display: none;
  }
}

/* Title of Progressbar */

.progressbar__heading {
  text-align: center;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 50px;
}

/* Display Info-Text and Bar in one line */

.progressbar__inline {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

/* Info-Text left and right from Progressbar */

.progressbar__infoRightLeft {
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

/* Info-Text above Progressbar */

.progressbar__phases {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 110px;
}

/* Progressbar style */
.progressbar-container {
  height: auto;
}

.progressbar__itself {
  overflow: visible;
  width: 1400px;
  margin-bottom: 200px;
}

.progressbar__points {
  top: 16px;
  display: flex;
  flex-direction: row;
  width: 1200px;
  max-width: 100%;
  position: relative;
  justify-content: space-evenly;
}

/* active pulsating ring */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(220, 220, 220, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.pulse {
  box-shadow: 0 0 0 rgba(220, 220, 220, 0.4);
  animation: pulse 3s infinite;
}

/* Style of bullet point */

.progressbar__point {
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  background: #c4c3c3; /* #000 */
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: relative;
}

/* Style of active bullet point */

.progressbar__point.active {
  background: #DE5773;
}

/* Style of active line */

.progressbar__point.active::before {
  height: 4px;
  bottom: 4px;
  /* background: #c4c3c3; /* #DE5773*/
  background: var(--progressbar-pseudo);
  z-index: -3;
}

/* Style of lines */

.progressbar__point::before {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  content: '';
  position: absolute;
  bottom: 5px;
  background: #c4c3c3;
  opacity: 0.8;
  height: 2px;
  z-index: -3;
}

#clicked__0::before {
  width: 190px;
  left: -190px;
}

#clicked__1::before {
  width: 287px;
  left: -287px;
}

#clicked__2::before {
  width: 255px;
  left: -255px;
}

#clicked__3::before {
  width: 145px;
  left: -145px;
}

#clicked__4::before {
  width: 33px;
  left: -33px;
}

#clicked__5::before {
  width: 102px;
  left: -102px;
}

/* Additional line before first and after last bullet point */

/*.progressbar__point:first-child {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  background: #DE5773;
}*/

#clicked__5::after {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  content: '';
  z-index: -1;
  position: absolute;
  width: 100px;
  left: 13px;
  bottom: 5px;
  background: #c4c3c3;
  opacity: 0.8;
  height: 2px;
}

/* .progressbar__point:first-child::after {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  content: '';
  z-index: -1;
  position: absolute;
  width: 158px;
  left: -152px;
  bottom: 5px;
  background: #DE5773;
  height: 4px;
} */

#clicked__5.active::after {
  height: 4px;
  bottom: 4px;
  /* background: #DE5773; */
  background: var(--progressbar-pseudo);
}

/* Custom Scrollbar */

/*
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 7.5px solid transparent;
  background-clip: content-box;
}

 */
/*********************
Rectangle Background-Theme (SVG)
************************/

.progressbar__background {
  top: 12px;
  z-index: -2;
  position: absolute;
  top: -20px;
  left: 205px;
}

.progressbar__background_active {
  top: 12px;
  z-index: -2;
  position: absolute;
  top: -20px;
  left: 205px;
}

#rect1,
#rect2,
#rect3,
#rect4 {
  margin-right: -7px;
}

#rect1 rect {
  height: 80px;
  width: 80px;
  fill: #f2f2f2;
}

#rect2 rect {
  height: 80px;
  width: 230px;
  fill: #def0f4;
  opacity: 0.8;
}

#rect3 rect {
  height: 80px;
  width: 347px;
  fill: #fcf69a;
  opacity: 0.8;
}

#rect4 rect {
  height: 80px;
  width: 395px;
  fill: #dedea7;
  opacity: 0.8;
}


/********************
Text
******************/

.progressbar__text-top {
  margin-left: 310px;
  font-size: 12px;
  top: -70px;
  display: flex;
  flex-direction: row;
  position: absolute;
}

.progressbar__text-bottom {
  margin-left: 315px;
  font-size: 12px;
  top: 85px;
  display: flex;
  flex-direction: row;
  position: absolute;
}

.text1 {
  margin-left: -5px;
}

.text2 {
  margin-left: 195px;
}

.progressbar__text-bottom .text2 {
  margin-left: 215px;
}

.text3 {
  margin-left: 203px;
}

.text4 {
  margin-left: 55px;
}

.progressbar__text-mid {
  top: -5px;
  margin-left: 105px;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  position: absolute;
}

#text2 {
  margin-left: 63px;
}

#text3 {
  margin-left: 33px;
}

#text4 {
  margin-left: 35px;
}

#text5 {
  margin-left: 175px;
}

#text6 {
  margin-left: 10px;
}

#text7 {
  margin-left: 200px;
}


/********************
Diaomnds
********************/

.progressbar__diamonds {
  display: flex;
  flex-direction: row;
  margin-left: 477px;
  top: -18px;
  position: absolute;
}

#square1,
#square2,
#square3 {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  font-size: 10px;
  height: 80px;
  width: 80px;
  z-index: 1;
  transition: transform 500ms ease-in-out, color 200ms ease-in-out;
}

.square {
  transition: 0.1s ease-in;
  background: #fff;
  border: 1px solid #000;
  line-height: 15px;
}

.active_square {
  color: #fff;
  border: 1px solid #fff;
  background: #A8CF33;
}

#square3.active_square {
  background: #238B19;
}

#square1 p,
#square2 p,
#square3 p {
  transform: rotate(-45deg);
  margin-top: 13px;
  text-align: center;
}

#square1 {
  margin-left: 5px;
}

#square2 {
  margin-left: 188px;
}

#square3 {
  margin-left: 244px;
}

/**********************
 Markers
 ***********************/

.progressbar__markers-right-b {
  position: absolute;
  top: 30px;
  left: 285px;
}

.progressbar__markers-straight-b {
  position: absolute;
  top: 25px;
  left: 285px;
}

.progressbar__markers-right {
  position: absolute;
  top: -50px;
  left: 285px;
}

.progressbar__markers-straight {
  position: absolute;
  top: -40px;
  left: 285px;
}

#marker1_straight,
#marker1_right,
#marker1_straight-b,
#marker1_right-b {
  margin-left: -2px;
}

#marker2_straight,
#marker2_right,
#marker2_straight-b,
#marker2_right-b {
  margin-left: 234px;
}

#marker3_straight,
#marker3_right,
#marker3_straight-b,
#marker3_right-b {
  margin-left: 236px;
}

#marker4_straight,
#marker4_right {
  visibility: hidden;
  margin-left: 209px;
}

#marker4_straight-b,
#marker4_right-b {
  margin-left: 204px;
}

#marker5_straight,
#marker5_right {
  visibility: hidden;
  margin-left: 94px;
}

#marker5_straight-b,
#marker5_right-b {
  margin-left: 94px;
}

#marker1_straight line,
#marker2_straight line,
#marker3_straight line,
#marker4_straight line,
#marker5_straight line {
  stroke: #000000;
  stroke-width: 2px;
}

#marker1_right line,
#marker2_right line,
#marker3_right line,
#marker4_right line,
#marker5_right line {
  stroke: #000000;
  stroke-width: 0.8px;
}

#marker1_straight-b line,
#marker2_straight-b line,
#marker3_straight-b line,
#marker4_straight-b line,
#marker5_straight-b line {
  stroke: #000000;
  stroke-width: 2px;
}

#marker1_right-b line,
#marker2_right-b line,
#marker3_right-b line,
#marker4_right-b line,
#marker5_right-b line {
  stroke: #000000;
  stroke-width: 0.8px;
}

/* Background Animation */
@keyframes background-animation {
  0% {
    width: 0%;
  }
  70% {
    width: 80%;
  }
  100% {
    width: 90%;
  }
}

#rect1,
#rect2,
#rect3,
#rect4,
#rect1_active,
#rect2_active,
#rect3_active,
#rect4_active {
  margin-right: -7px;
}

#rect1 rect {
  z-index: -4;
  height: 80px;
  width: 80px;
  fill: #f2f2f2;
  transition: fill 0.3s ease;
}

#rect2 rect {
  z-index: -4;
  height: 80px;
  width: 230px;
  fill: #a9e8f7;
  transition: fill 0.3s ease;

}

#rect3 rect {
  z-index: -4;
  height: 80px;
  width: 347px;
  fill: #fcf269;
  transition: fill 0.3s ease;
}

#rect4 rect {
  z-index: -4;
  height: 80px;
  width: 395px;
  fill: #dddd7a;
  transition: fill 0.3s ease;


}

/* Active Background style */
#rect1_active rect {
  z-index: -4;
  height: 80px;
  width: 80px;
  fill: #f2f2f2;
  transition: fill 0.3s ease;
  animation: background-animation 1.2s;
  animation-play-state: paused;
}

#rect2_active rect {
  z-index: -4;
  height: 80px;
  width: 230px;
  fill: #a9e8f7;
  transition: fill 0.3s ease;
  animation: background-animation 1.2s;
  animation-play-state: paused;

}

#rect3_active rect {
  z-index: -4;
  height: 80px;
  width: 347px;
  fill: #fcf269;
  transition: fill 0.3s ease;
  animation: background-animation 1.2s;
  animation-play-state: paused;

}

#rect4_active rect {
  z-index: -4;
  height: 80px;
  width: 395px;
  fill: #dddd7a;
  transition: fill 0.3s ease;
  animation: background-animation 1.2s;
  animation-play-state: paused;

}

/* low opacity Background */
.rect__opacityMin {
  fill-opacity: 0.2;

}

/* hight opacity */
.rect__opacityMax {
  fill-opacity: 1;
}


/* WORKFLOW SLIDER */

.c-progressbar {
  margin-bottom: -130px;
}

.workflow-opener {
  margin-bottom: 30px;
  margin-top: -30px;
}

.workflow_slider-container {
  margin-top: 80px;
  height: auto;
  max-width: 100%; /* 1440px */
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1850px) {
  .workflow_slider-container {
    padding: 0 6%;
  }
}

.workflow_slider {
  width: 100%;
  padding: 0 5%;
}

.slick-initialized .slick-slide {
  display: flex !important;
  flex-direction: row;
}

.workflow-slide-txt-container {
  padding: 0 20px;
  width: 55%;

  .workflow-slide-txt-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-30)/36px var(--unnamed-font-family-futura-pt);
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;

    hr {
      margin-top: 0;
    }
  }

  .workflow-slide-txt-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25)/var(--unnamed-line-spacing-35) var(--unnamed-font-family-futura-pt);
    line-height: 30px;
    font-size: 20px;
  }
}

.workflow-slide-img-container {
  margin-top: 90px;
  padding: 0 20px;
  width: 45%;

  img {
    width: 100%;
    height: auto;
  }
}

.slick-dots li button:before {
  font-size: 25px;
  line-height: 25px;
}

@media screen and (max-width: 996px) {
  .workflow_slider {
    padding: 0;
  }
  .slick-initialized .slick-slide {
    flex-direction: column;
  }
  .workflow-slide-txt-container {
    padding: 0;
    width: 100%;
  }
  .workflow-slide-img-container {
    margin-top: 30px;
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: 996px) {
  .workflow_slider-container {
    padding: 0 5%;
  }
}

@media screen and (max-width: 550px) {
  #workflowAndSliderContainer {
    padding: 0 5%;
  }
  .workflow-slide-txt-container {
    .workflow-slide-txt-title {
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 26px;
    }

    .workflow-slide-txt-txt {
      line-height: 1.5;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .workflow_slider-container {
    padding: 0 0;
  }
  .workflow_slider {
    padding: 0 0;
  }
}

/* CONTENT MARGIN */

/* TOP */
.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mt-300 {
  margin-top: 300px !important;
}


/* BOTTOM */

.mb-0 {
  margin-top: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mb-300 {
  margin-bottom: 300px !important;
}


/* mobile 1210 special */
@media screen and (max-width: 1210px) {
  /* TOP */
  .mobile1210_mt-0 {
    margin-top: 0 !important;
  }
  .mobile1210_mt-20 {
    margin-top: 20px !important;
  }
  .mobile1210_mt-50 {
    margin-top: 50px !important;
  }
  .mobile1210_mt-80 {
    margin-top: 80px !important;
  }
  .mobile1210_mt-120 {
    margin-top: 120px !important;
  }
  /* BOTTOM */
  .mobile1210_mb-0 {
    margin-bottom: 0 !important;
  }
  .mobile1210_mb-20 {
    margin-bottom: 20px !important;
  }
  .mobile1210_mb-50 {
    margin-bottom: 50px !important;
  }
  .mobile1210_mb-80 {
    margin-bottom: 80px !important;
  }
  .mobile1210_mt-120 {
    margin-top: 120px !important;
  }
}

/* mobile 996 */
@media screen and (max-width: 996px) {
  /* TOP */
  .mobile_mt-0 {
    margin-top: 0 !important;
  }
  .mobile_mt-10 {
    margin-top: 10px !important;
  }
  .mobile_mt-20 {
    margin-top: 20px !important;
  }
  .mobile_mt-30 {
    margin-top: 30px !important;
  }
  .mobile_mt-50 {
    margin-top: 50px !important;
  }
  .mobile_mt-80 {
    margin-top: 80px !important;
  }
  .mobile_mt-120 {
    margin-top: 120px !important;
  }
  .mobile_mt-200 {
    margin-top: 200px !important;
  }
  .mobile_mt-300 {
    margin-top: 300px !important;
  }
  /* BOTTOM */
  .mobile_mb-0 {
    margin-bottom: 0 !important;
  }
  .mobile_mb-10 {
    margin-bottom: 10px !important;
  }
  .mobile_mb-20 {
    margin-bottom: 20px !important;
  }
  .mobile_mb-30 {
    margin-bottom: 30px !important;
  }
  .mobile_mb-50 {
    margin-bottom: 50px !important;
  }
  .mobile_mb-80 {
    margin-bottom: 80px !important;
  }
  .mobile_mb-120 {
    margin-bottom: 120px !important;
  }
  .mobile_mb-200 {
    margin-bottom: 200px !important;
  }
  .mobile_mb-300 {
    margin-bottom: 300px !important;
  }
}

/* BUBBLE ANIMATION */

/* @-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-z {
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spin-z {
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// background
.teaser-bubble-animation path:nth-child(4n)  {
  animation: grow 12s linear infinite both;
  transform-origin: 40% 25%;
  transform-box: fill-box;
}

.teaser-bubble-animation path:nth-child(7n), .bubble-animation6 {
  animation: spin 55s linear infinite both;
  transform-origin: 50% 50%;
  transform-box: fill-box;
}

.bubble-animation1, .bubble-animation2, .bubble-animation3, .bubble-animation4, .bubble-animation5 {
  animation: spin 40s linear infinite both;
  transform-origin: 50% 50%;
  transform-box: fill-box;
} */

.faq-answer {
  div {
    // display: none;
  }
}


/* WORKS ROOT */
.works-teaser-image {
  height: 100%;
  aspect-ratio: 16/10;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #eceef1;
  margin-bottom: 8px;
}

.works-teaser-label {
  font-family: futura-pt;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
  width: 100%;
  cursor: default;
}

.works-teaser-label:hover {
  text-decoration: none;
  cursor: default;
}

.works-teaser-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;
  column-gap: 18px;
  width: 100%;
}

.works-teaser:hover {
  cursor: default;
}

.works-teaser {
  position: relative;
  flex: calc(25% - 13.5px);
  max-width: calc(25% - 13.5px);
  /* (100vw - 10% padding) *25% - 13.5px */
  // height: calc((22.5vw - 13.5px)*0.6);
  aspect-ratio: 16/10;

  .works-teaser-image:hover {
    cursor: default;
  }

  &.active {
    cursor: pointer;

    .works-teaser-image {
      // border-color: #2580ea;
      border-color: #888888;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      .works-teaser-label {
        color: #D1D10F;
        cursor: pointer;
      }
    }
  }
}

.works-teaser-active-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 12%;
  aspect-ratio: 1/1;

  .indicator-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(236, 238, 241, 0.7);
    border-bottom-left-radius: 100%;
    transform: translate(-1px, 1px);
  }

  .indicator-circle {
    width: 100%;
    height: 100%;
    position: relative;

    .foreground {
      width: 35%;
      height: 35%;
      position: absolute;
      top: 35%;
      left: 65%;
      transform: translate(-65%, -35%);
      border-radius: 50%;
      background: #D1D10F;
      border: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 1600px) {
  .works-teaser {
    flex: calc(33.333% - 13.5px);
    max-width: calc(33.3333% - 13.5px);
    // height: calc((22.5vw - 13.5px) * 0.75);
    aspect-ratio: 16/10;
  }
}

@media screen and (max-width: 1250px) {
  .works-teaser {
    flex: calc(50% - 13.5px);
    max-width: calc(50% - 13.5px);
    // height: calc(25vw - 13.5px);
    aspect-ratio: 16/10;
  }
}

@media screen and (max-width: 768px) {
  .works-teaser {
    flex: 100%;
    max-width: 100%;
    aspect-ratio: 16/10;
  }
}

.works-all-filter-label {
  font-weight: bold;
  width: 10%;
  margin: 0;
  cursor: pointer;
}

.works-filter-tag {
  font-size: 15px;
  color: #bec0c4;
  border: 1px solid #eceef1;
  color: #000000;
  // border: 1px solid #000000;
  border-radius: 3px;
  text-align: center;
  /*width: 10%;*/
  padding: 0px 12px;
  margin: 0;
  cursor: pointer;
  user-select: none;
}

.works-filter-tag.active {
  border: 1px solid #d1d10f;
  color: #d1d10f;
}

.works-filter-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  column-gap: 12px;
  row-gap: 12px;
  line-height: 38px;
}

/* WORKS */
.project-name {
  margin-bottom: 0;
  margin-top: -10px;
}

.project-subtitle {
  font-size: 14px;
  padding-top: 3px;
}

.project-title-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.project-title-row-left {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.project-title-row-right {
  display: flex;
  flex-direction: column;
  width: 30%;
}

@media screen and (max-width: 768px) {
  .project-title-row {
    flex-direction: column-reverse;
  }
  .project-title-row-right {
    margin-bottom: 15px;
  }
}

.teaser-works-challenge-title {
  margin-top: 50px;
  font-weight: bolder;
  font-size: 20px;
  font-family: futura-pt, sans-serif;
}

.teaser-works-challenge-title:hover {
  text-decoration: none;
}

.teaser-works-challenge-text {
  font-size: 15px;
  font-family: unset;

  p {
    margin-bottom: 50px;
  }
}

.teaser-works-customer-name {
  font-weight: normal;
  margin-top: 5px;
  font-size: 16px;
  font-family: futura-pt, sans-serif;
}

.works-teaser-title {
  font-size: 80px;
  line-height: 1.2;

  .font-serif {
    color: #192863;
    font-family: 'Times New Roman', 'serif';
  }

  .font-sans-serif {
    color: #192863;
    font-family: 'Roboto', 'sans-serif';
    font-weight: bold;
  }
}

@media screen and (max-width: 1600px) {
  .works-teaser-title {
    font-size: 70px;
  }
}

@media screen and (max-width: 996px) {
  .works-teaser-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 400px) {
  .works-teaser-title {
    font-size: 40px;
  }
}

.works-content {
  display: flex;
  width: 100%;
}

.works-content .works-content-column:nth-child(1) {
  padding-right: 5%;
}

.works-content-column {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .works-content .works-content-column:nth-child(1) {
    width: 50%;
  }
  .works-content .works-content-column:nth-child(2) {
    width: 50%;
  }
}

@media screen and (max-width: 996px) {
  .works-content-column {
    padding-left: 0 !important;
    width: 100% !important;
  }
  .works-content .works-content-column:nth-child(1) {
    margin-bottom: 50px;
  }
  .works-content {
    flex-direction: column;
  }
}

.works-idea-impact {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.works-idea-impact-child {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .works-idea-impact {
    flex-direction: column;
  }
  .works-idea-impact-child {
    width: 100%;
  }
}

.works-idea {
  font-family: futura-pt, sans-serif;
  font-weight: bolder;
  padding-bottom: 3px;
  font-size: 20px;
}

.works-idea:hover {
  text-decoration: none;
}

.works-impact {
  font-family: futura-pt, sans-serif;
  font-size: 20px;
  font-weight: bolder;
  padding-bottom: 3px;
}

.works-impact:hover {
  text-decoration: none;
}

.works-customer-teaser {
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 0;
  font-family: futura-pt, sans-serif;
}

.works-customer-subtitle {
  font-weight: normal;
  font-size: 16px;
  font-family: futura-pt, sans-serif;
}

.works-customer-text {
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  margin-bottom: 50px;
}

/* .customer-info-container {
  display: flex;
  flex-direction: column;
  width: 30%;
} */
@media screen and (max-width: 1250px) {
  .customer-info-container {
    width: 100%;
  }
}

.customer-tag {
  line-height: 1.3;
}

.customer-tag:hover {
  text-decoration: none;
}

.customer-tags {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.customer-description {
  margin-top: 20px;
}

.customer-description:hover {
  text-decoration: none;
}

.works-customer-img {
  cursor: pointer;
  margin-top: 30px;
  width: 70%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.project-gallery-img {
  /*(2x50px padding each side)/4 */
  width: calc(33% - 10px);
}

.customer-column {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 220px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.project-column {
  // background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
  margin-top: 50px;
  row-gap: 25px;
  padding-right: 30px;
}

.works-forward-back {
  //font-weight: bold;
  font-family: futura-pt;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
}

.works-forward-back:hover {
  text-decoration: none;
}

.teaser-title {
  &.teaser-works {
    padding-bottom: 5px !important;
  }
}

.cc-btn .cc-dismiss {
  font-family: Roboto, sans-serif;
  font-size: 12px !important;
}

.cc-btn:hover, .cc-dismiss:hover {
  text-decoration: none;
}

.breadcrumb-item {
  &.active {
    color: #d1d10f;
  }
}

.breadcrumb {
  user-select: none;
}

.teaser {
  user-select: none;
}

.call-to-action-container {
  user-select: none;
}

.customer_root-text {
  user-select: none;
}

.technologies {
  user-select: none;
}

.solution-link {
  /* TODO NO TEXT SELECTION */
}

.header-menu-a {
  user-select: none;
}

.for-you {
  user-select: none;
}

.contact-form-modal-link {
  user-select: none;
}

.faq_page-header-menu-a {
  user-select: none;
}

.blog-header-menu-a {
  user-select: none;
}

.first-level-nav-logo {
  user-select: none;
}

.swissmadesoft-logo-img {
  user-select: none;
  pointer-events: none;
}

.solution-links {
  h2 {
    user-select: none;
  }
}

.solution-name {
  user-select: none;
}

.solution-teaser-title {
  user-select: none;
}

.solution-image2 {
  user-select: none;
  pointer-events: none;
}

.technologies-logo-element {
  img {
    user-select: none;
    pointer-events: none;
  }
}

.service-teaser {
  user-select: none;

  img {
    pointer-events: none;
  }
}

.contact-form-opener-text {
  user-select: none;
}

.contact-form-description {
  user-select: none;
}

.contact-form-column-left {
  h2 {
    user-select: none;
  }
}

.contact-form-label {
  user-select: none;
}

#contact {
  input {
    user-select: none;
  }

  textarea {
    user-select: none;
  }
}

br {
  user-select: none;
}

.required-label {
  user-select: none;
}

.data-protection-div {
  user-select: none;
}

.data-protection-a {
  user-select: none;
}

.content-title {
  a {
    user-select: none;
  }
}

.content-description {
  a {
    user-select: none;
  }
}

.column-content-text {
  user-select: none;
}

.faqs {
  a {
    user-select: none;
  }

  p {
    user-select: none;
  }
}

.teaser-image {
  pointer-events: none;
}

.image_video-image-img {
  user-select: none;
  pointer-events: none;
}

.content-title-with-image {
  a {
    user-select: none;
  }
}

.ttag_group-name {
  user-select: none;
}

.ttag-img {
  user-select: none;
  pointer-events: none;
}

.faq-icon-img {
  user-select: none;
  pointer-events: none;
}

.ttag-name {
  user-select: none;
}

.workflow-title {
  user-select: none;
}

.workflow-slide-img-container {
  img {
    user-select: none;
    pointer-events: none;
  }
}

.workflow-opener {
  user-select: none;
}

.gallery-img {
  pointer-events: none;
}

.content-description-with-image {
  a {
    user-select: none;
  }

  p {
    user-select: none;
  }
}

.grid-elem-title {
  user-select: none;
}

.grid-elem-teaser {
  user-select: none;
}

.title {
  user-select: none;
}

.header-menu-arrow-icon-a_root {
  cursor: pointer;
}

.secondLevelNavContainer {
  user-select: none;
}

.secondLevelNavContainer_m {
  user-select: none;
}

.employee-teasers {
  user-select: none;
  pointer-events: none;
}

.teaser-title {
  user-select: none;
}

.teaser-text {
  user-select: none;
}

.page-teaser-cards {
  user-select: none;

  img {
    pointer-events: none;
  }
}

.content-description {
  a {
    user-select: none;
  }

  p {
    user-select: none;
  }
}

.faqs-list {
  user-select: none;
}

.faq-tags {
  user-select: none;
}

.faq-image-and-description {
  user-select: none;

  img {
    pointer-events: none;
  }
}

.blog {
  user-select: none;

  img {
    pointer-events: none;
  }
}

.blog-teaser-text:hover {
  text-decoration: none;
}

.contact-modal-text {
  user-select: none;
}

.contact-modal-text:hover {
  text-decoration: none;
}

.contact-modal-title {
  user-select: none;
}

.navContainer {
  user-select: none;

  img {
    pointer-events: none;
  }
}

.third-level-nav-teaserImage2-img {
  pointer-events: none;
  user-select: none;
}

.third-level-nav-teaserTitle {
  user-select: none;
}

.third-level-nav-teaserText {
  user-select: none;
}

.faq-question-text {
  user-select: none;
}

.faq-answer {
  p {
    user-select: none;
  }
}

.contact-in-nav-title {
  user-select: none;
}

.contact-in-nav-text {
  user-select: none;
}

.contact-in-nav-text:hover {
  text-decoration: none;
}

.call-to-action-img {
  pointer-events: none;
}

.call-to-action-img2 {
  pointer-events: none;
}

.collapsed-text {
  p {
    white-space: normal;
  }
}

.works-teaser {
  user-select: none;

  img {
    pointer-events: none;
  }
}


/************************/
/*****   SHOWCASE   *****/
/************************/

.showcase {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: row;
}

.showcase-1st-half {
  display: flex;
  justify-content: center;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  /*animation: left-to-right 800ms ease-out forwards;
  animation-delay: 0s;*/
}

.showcase-expansion-0-50 {
  animation: left-to-right-expansion-0-50 800ms ease-out forwards !important;
}

.showcase-expansion-50-100 {
  animation: left-to-right-expansion-50-100 800ms ease-out forwards !important;
}

.showcase-expansion-0-100 {
  animation: left-to-right-expansion-0-100 800ms ease-out forwards !important;
}

.showcase-implosion-50-0 {
  animation: left-to-right-implosion-50-0 800ms ease-out forwards !important;
}

.showcase-implosion-100-50 {
  animation: left-to-right-implosion-100-50 800ms ease-out forwards !important;
}

.showcase-implosion-100-0 {
  animation: left-to-right-implosion-100-0 1600ms ease-out forwards !important;
}

.showcase-device-expansion {
  animation: left-to-right-expansion-0p-270p 800ms ease-out forwards !important;
}

.showcase-device-implosion {
  animation: left-to-right-implosion-270p-0p 800ms ease-out forwards !important;
}

.showcase-expansion-bottom-top {
  animation: bottom-to-top-expansion-0-100 800ms ease-out forwards !important;
}

.showcase-expansion-top-bottom {
  animation: top-to-bottom-expansion-0-100 800ms ease-out forwards !important;
}

@keyframes bottom-to-top-expansion-0-100 {
  from {
    height: 0
  }
  to {
    height: 100%
  }
}

@keyframes top-to-bottom-expansion-0-100 {
  from {
    height: 0
  }
  to {
    height: 100%
  }
}

@keyframes left-to-right-expansion-0-50 {
  from {
    width: 0
  }
  to {
    width: 50%
  }
}

@keyframes left-to-right-expansion-50-100 {
  from {
    width: 50%
  }
  to {
    width: 100%
  }
}

@keyframes left-to-right-expansion-0-100 {
  from {
    width: 0
  }
  to {
    width: 100%
  }
}

@keyframes left-to-right-implosion-50-0 {
  from {
    width: 50%
  }
  to {
    width: 0
  }
}

@keyframes left-to-right-implosion-100-50 {
  from {
    width: 100%
  }
  to {
    width: 50%
  }
}

@keyframes left-to-right-implosion-100-0 {
  from {
    width: 100%
  }
  to {
    width: 0
  }
}

@keyframes left-to-right-expansion-0p-270p {
  from {
    width: 0
  }
  to {
    width: 270px
  }
}

@keyframes left-to-right-implosion-270p-0p {
  from {
    width: 270px
  }
  to {
    width: 0
  }
}

.showcase-2nd-half {
  background-color: white;
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
}

.showcase-opener {
  position: absolute;
  top: 130px;
  left: -10px;
  cursor: pointer;
  z-index: +1;
}

.showcase-opener-full {
  position: absolute;
  top: 130px;
  left: 50%;
  cursor: pointer;
  color: white;
}

.showcase-closer {
  position: absolute;
  top: 130px;
  right: -8px;
  cursor: pointer;
  color: white;
}

.showcase-container {
  padding-left: 20px;
  height: 100%;
  width: 100%; /* 60% */
  max-width: 1100px;
  display: flex;
  justify-content: center; /* space-between */
  align-items: center;
  column-gap: 70px;
  row-gap: 50px;
}

@media only screen and (max-width: 1135px) {
  .showcase-container {
    flex-direction: column;
  }
}

.showcase-device {
  height: 570px;
  width: 270px;
  margin-top: 52px;
}

.showcase-form {
  height: 570px;
  width: 50%;
  min-width: 300px;
  margin-top: 15px;
  color: white;
}

.showcase-app {
  // zoom: 0.5;
  background-image: url('/public/assets/images/main/app_loading_screen.png');
  background-size: contain;
  height: 1140px; /* height: 570px X zoom: 0.5 */
  width: 540px; /* width: 270px X zoom: 0.5 */
  // max-width: 100%;
  // max-height: 100%;
  /* new fit fixed */
  height: 635px;
  width: 300px;
  transform-origin: 0 0;
  transform: scale(0.9);
}

.showcase-app-wrap {
  height: 570px;
  width: 270px;
  padding: 0;
}

.showcase-phone {
  pointer-events: none;
  background: url('/public/assets/images/main/Phone.png');
  background-size: cover;
  height: calc(100% + 75px);
  width: calc(100% + 190px);
  position: relative;
  top: -618px;
  left: -93px;
}

.showcase-2nd-half-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 95px;
  padding-right: 95px;
  overflow-y: scroll;
  padding-bottom: 70px;
}

.showcase-show-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase-goto-icon svg {
  fill: white;
}

.showcase-goto-form {
  cursor: pointer;
  color: white;
}

.showcase-goto-form:hover {
  text-decoration: none;
  color: #e1e1e1;
}

@media only screen and (max-width: 768px) {
  .showcase-2nd-half-content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.showcase-pcard-logo {
  width: 385px;
  height: 30.6px;
}

.showcase-qr-code {
  background-image: url('/public/assets/images/main/app-qr.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 85px;
  width: 85px;
}

.showcase-qr-code-wrap {
  padding: 8px;
  width: 113px;
  margin-top: 5px;

  --b: 5px; /* thickness of the border */
  --c: #dcdcdc; /* color of the border */
  --w: 20px; /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0    / var(--_p),
  conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0    / var(--_p),
  conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
  conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
}

.showcase-google-play-icon {
  background-image: url('/public/assets/images/main/google-play-badge.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 132px;
  cursor: pointer;
  pointer-events: all;
  margin-top: -2px;
  transition: 300ms ease;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
}

.showcase-google-play-icon:hover {
  filter: grayscale(0%);
}

.showcase-email-input {
  border: 1px solid black;
  height: 30px;
  font-size: 11px;
  padding-left: 10px;
}

.showcase-email-input:focus {
  outline: none !important;
}

.showcase-email-input-submit {
  background-color: black;
  color: white;
  font-size: 11px;
  height: 30px;
  width: 80px;
  text-align: center;
  border: none;
}

.showcase-qr-column {
  width: 122px;
  row-gap: 10px;
}

.showcase-google-play-column {
  width: calc(100% - 122px);
  row-gap: 8px;
}

.showcase-qr-and-google-play-row {
  padding-left: 0;
  column-gap: 50px;
  row-gap: 20px;
  justify-content: flex-start;
}

.showcase-google-play-icon-wrap {
  margin-bottom: 10px;
}

.showcase-input-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.android-download-icon {
  width: 16px;
  height: 16px;
  filter: invert(93%) sepia(20%) saturate(3042%) hue-rotate(5deg) brightness(92%) contrast(88%); //color close to #d1d10f
}

.showcase-closer-2 {
  display: none;
}

.showcase-closer-3 {
  display: none;
}

@media screen and (max-width: 1135px) {
  .showcase-2nd-half-content {
    /*display: none;*/
    padding-top: 120px;
  }
  .showcase-pcard-logo {
    display: none;
  }
  .showcase-1st-half-mobile-first-expansion {
    animation: left-to-right-expansion-50-100 800ms ease-out forwards !important;
  }
  .showcase-1st-half {
    height: 50%; /* !important */
    position: unset;
  }
  .showcase-2nd-half {
    width: 100% !important;
    position: unset;
    height: 50% !important;

    .showcase-opener-full {
      top: 45%;
      transform: rotate(90deg);
      left: 40px;
    }

    .showcase-opener {
      display: none;
    }
  }
  .showcase-2nd-half-content {
    position: unset;
  }
  .showcase {
    height: 200% !important;
    overflow-y: scroll !important;
    flex-direction: column !important;
  }
  .showcase-closer-2 {
    display: unset;
    color: black;
  }
  .showcase-closer-3 {
    display: unset;
    color: black;
    right: unset;
    left: 40px;
    transform: rotate(270deg);
    top: 85px;
  }
}

.prevent-scroll {
  overflow-y: hidden !important;
  height: 100% !important;
}

@media screen and (min-width: 1136px) {
  .showcase-1st-half {
    width: 50%;
  }
}


.showcase-text {
  min-width: 150px;
  font-size: 13px;

  &.bold {
    font-weight: 600;
  }

  &.small {

  }

  &.big {
    font-size: 23px;
    font-family: 'futura-pt';
  }

  &.green {
    color: #d1d10f;
    font-size: 17px;

    font-family: 'futura-pt';
  }

  &.blue {
    color: #008fcd;
    font-size: 17px;
    font-family: 'futura-pt';
  }

  &.grey {
    color: #747474;
    font-size: 17px;
    font-family: 'futura-pt';
  }
}

@media only screen and (max-width: 550px) {
  .showcase-device {
    height: 445px;
    width: 220px;
  }
  .showcase-app {
    max-height: calc(100% + 67px);
    max-width: calc(100% + 28px);
  }
  .showcase-app-wrap {
    height: 500px;
    width: 237px;
    padding: 0;
    transform: translate(-7px, 62px);
  }
  .showcase-phone {
    height: calc(100% + 120px);
    top: -480px;
  }
  .showcase-closer {
    top: 80px;
  }
  .showcase-closer:not(.showcase-closer-3) {
    right: 5px;
  }
  .showcase-closer svg, #showcase-opener-full svg {
    width: 50px;
  }
  .showcase-qr-column, .showcase-google-play-column {
    width: 100%;
  }
  .showcase-email-input {
    width: 100%;
  }
  #showcase-opener-full {
    left: 10px !important;
  }
  .showcase-show-form {
    margin-top: 110px;
  }
}

.service-teaser-row {
  margin-bottom: 150px !important;
}

.company-child-teaser-row {
  margin-top: 10px !important;
}

/*******************************/
/****** PRINT VERSION FIX ******/
/*******************************/
@media print {
  /** homepage **/
  .technologies {
    padding-top: 130px;
  }
  .cards {
    margin-top: -40px !important;
    column-gap: 25px;
    max-height: unset;

    .service-card {
      margin: 25px 0 0 !important;
      border: 1px solid #c2c2c2;
      height: 500px;

      &:nth-child(5), &:nth-child(6) {
        margin-top: 130px !important;
        height: 520px;
      }

      p {
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
    }

    margin-bottom: 500px !important;
  }
  .customers {
    margin-top: 350px !important;
  }
  .customer_root-text {
    margin-top: 500px !important;
    margin-bottom: 200px !important;
  }
  .call-to-action {
    padding-bottom: 20px !important;
  }
  .call-to-action-container {
    margin-top: 80px !important;
  }
  .call-to-action-right {
    margin-top: -5px !important;
  }
  .footer-container {
    margin-bottom: 20px !important;
  }
  .footer-socials {
    display: none;
  }
  .copyright {
    margin-top: 40px;
  }
  .links {
    opacity: 0 !important;
  }
  #btn-footer-more {
    opacity: 0;
  }
  /** solutions **/
  .contact-form {
    display: none;
  }
  .c-progressbar {
    display: none;
  }
  .ttags-row {
    margin-top: 400px !important;
    padding-top: 60px !important;
  }
  .branding-solution-ttags-row {
    margin-top: 600px !important;
  }
  .workflow_slider-container {
    margin-top: 0 !important;
  }
  .not-homepage {
    padding-top: 110px;
  }
  .solution-first-row {
    margin-top: 110px !important;
  }
  .branding-solution-first-row {
    padding-top: 110px !important;
  }
  .app-solution-first-row {
    margin-top: -20px !important;
  }
  .web-solution-first-row {
    page-break-before: always;
  }
  .software-solution-first-row {
    padding-top: 80px !important;
  }
  .solution-last-row {
    margin-bottom: -710px !important;
  }
  .contact-form-background {
    display: none;
  }
  .software-solution-spacer {
    width: 100%;
    height: 550px !important;
  }
  /** service **/
  .service-teaser-cards {
    margin-top: 60px !important;
    column-gap: 25px;
    max-height: unset;

    .service-teaser-card {
      margin: 25px 0 0 !important;
      border: 1px solid #c2c2c2;
      height: 500px;

      &:nth-child(3), &:nth-child(4) {
        margin-top: 130px !important;
      }

      &:nth-child(5), &:nth-child(6) {
        margin-top: 80px !important;
        height: 520px;
        margin-bottom: 300px !important;
      }

      &:nth-child(7), &:nth-child(8) {
        margin-top: 100px !important;
      }

      p {
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
    }

    margin-bottom: 0 !important;
  }
  .service-teaser-row {
    margin-bottom: 40px !important;
  }
  /** service2 **/
  .service2-first-row {
    margin-top: 100px !important;
  }
  /** company **/
  .company-child-teaser-row {
    margin-top: 120px !important;
    margin-bottom: 150px !important;
  }
  .team-first-row {
    margin-top: 150px !important;
  }
  .employee-teasers-row {
    padding-top: 110px !important;
  }
  .employee-teaser {
    &:nth-child(7) {
      padding-top: 120px !important; /*70px*/
    }
  }
  .team-customer-index-row {
    display: none;
  }
  .employee-teasers {
    margin-bottom: -20px !important;
  }
  .technologies-first-row {
    margin-top: 150px !important;
  }
  .technologies-customer-index-row {
    margin-bottom: 200px !important;
  }
  .technologies-ttags-company-row {
    padding-top: 150px !important;
  }
  .link-ttags-10, .link-ttags-11, .link-ttags-12 {
    margin-top: 185px !important;
    margin-bottom: 230px !important;
  }
  .link-ttags-13 {
    margin-bottom: 500px !important;
  }
  .jobs-row {
    margin-bottom: 200px !important;
  }
  /** works **/
  footer {
    page-break-before: always;
  }
  .addedvalue-teaser-text {
    span {
      font-size: 25px !important;
      line-height: 37px !important;
    }
  }
  .addedvalue-first-row {
    margin-top: 20px !important;
  }
  .addedvalue-faq-row {
    page-break-before: always;
    padding-top: 60px !important;
  }

}

.in-iframe {
  min-width: unset !important;
  body {
    width: 100% !important;
    scrollbar-width: unset;
  }

  .content {
    min-width: unset !important;
  }

  .cc-banner {
    display: none !important;
  }

}

