.pointer {
  cursor: pointer;
}

.js-template {
  display: none;
}

.relative {
  position: relative;
}

a.full-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.fontsize-13 {
  font-size: 13px;
}

.fontsize-16 {
  font-size: 16px;
}

.fontsize-18 {
  font-size: 18px;
}

.fontsize-21 {
  font-size: 21px;
}

.bg-white {
  background-color: #fff;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-decoration-underline {
  text-decoration: underline;
}
