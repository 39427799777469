.ct_image_gallery {
  .product-img-placeholder {
    color: #b1b3b4;
    background-color: #e3e4e52e;
  }
}

@include media-breakpoint-down(sm) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }
}

.gallery-thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.gallery-thumbnail-wrapper {
  width: 56px;
  height: 36px;
  margin: 10px 5px 0;
  overflow: hidden;
}

.gallery-thumbnail {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gallery-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 66.6%;

  .gallery-image-inner {
    .gallery-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}
