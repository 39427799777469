:root {
  --unnamed-color-4a841c: #4A841C;
  --unnamed-color-7fa722: #7FA722;
  --unnamed-color-d1d10f: #D1D10F;
  --unnamed-color-5e93a8: #5E93A8;
  --unnamed-color-e8f4d8: #E8F4D8;
  --unnamed-color-1d3277: #1D3277;
  --unnamed-color-cceaf6: #CCEAF6;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-bcc400: #BCC400;
  --unnamed-color-707070: #707070;
  --unnamed-color-000000: #000000;
  --unnamed-color-dbdddf: #DBDDDF;
  --unnamed-color-eceef1: #ECEEF1;
  --unnamed-color-f8f9fb: #F8F9FB;
  --unnamed-color-1d1d1b: #1D1D1B;
}

$white: #fff;
$black: #000;
$grey-1: #eceef1;
$grey-2: #F0F5F7;
$accent: #7fa722;

$theme-normal-text: $black;
$theme-inverted-text: $white;
$theme-link-text: $black;
$theme-active: $accent;
$theme-background: $white;
$theme-backdrop: $grey-1;
