
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 55px !important;
  background: $theme-background 0 0% no-repeat padding-box;
  opacity: 1;
  z-index: 1000;
  &.active{
    height: 100% !important;
  }

  .header-navigation {
    padding-top: 28px;
    padding-left: 112px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: bold 43px/50px "Times New Roman", serif;

    .copyright-r {
      font: 12px/12px "Arial", sans-serif;
      margin-top: 8px;
      margin-left: 1px;
    }

    & > a:hover {
      text-decoration: none;
    }
  }
}

.container-menu {
  display: flex;
  height: 55px !important;

  .container-navi {
    z-index: 4;

    nav {
      border-radius: 50%;
      background-color: whitesmoke;
      padding: 15px;
      margin-top: 33px;
      margin-left: 20px;
      box-shadow: 0 2px lightgrey;
    }

  }

  .container-logo {
    margin-top: 18px;
    margin-left: 30px;
    cursor: pointer;
  }

}

@media screen and (max-width: 768px) {
  .container-menu {
    .container-navi {
      nav {
        padding: 10px;
        margin-top: 38px;
        margin-left: 15px;
      }
    }

    .container-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 12px;
      text-align: center;

      svg {
        width: 70%;
        margin-left: 20px;
      }

    }
  }
}


/* HEADER MENU */

.header-menu-li {
  padding-top: 12px; /* 2vh */
  padding-bottom: 12px; /* 2vh */
  max-width: 90%;
}

.header-menu-ul {
  padding-top: 10vh;
  padding-left: 89px;
  list-style-type: none;
  font-size: 1.35em;
  font-weight: 410;
}

.header-menu-ul.bottom-nav-element {
  padding-top: 8vh;
}

@keyframes wantRight {
  from {transform: translateX(-30px);}
  25% {transform: translateX(-20px);}
  to {transform: translateX(0px);}
}

.animation-wantRight {
  animation: wantRight 1200ms ease;
  animation-play-state: paused;
}

.header-menu-a {
  color: white;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25) var(--unnamed-font-family-futura-pt);
  transition: color 300ms ease-in-out;
}

.header-menu-a:hover {
  color: black;
  text-decoration: none;
}

@keyframes flyRight {
  from {transform: translateX(0px);}
  70% {transform: translateX(-30px);}
  99% {transform: translateX(60px);}
  to {transform: translateX(100px);}
}

.animation-flyRight {
  animation: flyRight 400ms ease;
  animation-play-state: paused;
}

.header-menu-arrow-icon-a {
  float: right;
  padding-right: 10%;
  font-weight: 300;
}

.header-menu-arrow-icon-a:hover {
  text-decoration: none;
}

.for-you {
  color: black;
  font: var(--unnamed-font-style-normal) normal 900 var(--unnamed-font-size-30)/29px var(--unnamed-font-family-futura-pt);
}

.for-you:hover {
  text-decoration: none;
}

/* SECOND LEVEL HEADER MENU */
.second-level-header-menu-li {
  padding-top: 12px; /* 3vh */
  padding-bottom: 12px; /* 3vh */
  width: 100%;
  padding-left: 0;
  cursor: pointer;
  transition: background-color 300ms ease-in;
  /* border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.15); */
  &.hover {
    background-color: rgb(249 249 253 / 80%);
  }
}

.second-level-header-menu-li:first-child {
  /* border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.15); */
}

/* .second-level-header-menu-li:hover {
  background-color: rgb(249 249 253 / 80%);
} */

.second-level-header-menu-a {
  color: black;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/29px var(--unnamed-font-family-futura-pt);
  padding-left: calc(5% + 20px);
  display: inline-block; /* make padding also work after linebreak */
}

.second-level-header-menu-a:hover {
  text-decoration: none;
}

.second-level-header-menu-ul {
  padding-top: 10vh;
  list-style-type: none;
  font-size: 1.35em;
  font-weight: 300;
  padding-inline-start: 0;
  height: 100%
}


  .solutions-logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 8%;
    max-width: 25vw; /* 520px */
    margin-top: 50px; /* 140px */

    .technologies-logo-element {
      display: flex;
      /* height: calc((1.225em + 6vh + 1px) * 4 / 3); */
      aspect-ratio: 16/9;
      width: 25%;
      min-width: 100px;
      border: 1px dashed white; /* rgb(0, 0, 0, 0.10) */
      margin-right: -1px;
      margin-bottom: -1px;
      position: relative;
      z-index: unset;

      .logo-element-border {
        display: none; /* hide for now */
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: -30px;
        right: -30px;
        z-index: 1;
      }

      img {
        max-width: 90%;
        margin-left: 5%;
        max-height: 100%;
        object-fit: scale-down;
      }
    }
    .technologies-logo-element:last-child {
      .logo-element-border svg {
        display: none;
      }
    }
    .technologies-logo-element:nth-child(4n)  {
      .logo-element-border svg {
        display: none;
      }
    }
}


@media screen and (max-width: 1750px) {
    .solutions-logo-container {
      .technologies-logo-element {
        min-width: 100px;
      }
    }
}
@media screen and (max-width: 1440px) {
    .solutions-logo-container {
      .technologies-logo-element {
        min-width: 80px;
      }
    }
}
@media screen and (max-width: 1200px) {
  .solutions-logo-container {
    max-width: 100%;
    padding: 0 calc(5% + 20px);
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .solutions-logo-container {
    max-width: 100%;
    max-height: 250px;
    margin: 20%;
    margin-top: 50px;
  }
}
@media (min-width: 650px) and (max-width: 768px) {
  .solutions-logo-container {
    margin: 40px 7.5%;
    max-width: 100%;
    padding: 0 calc(5% + 20px);
    .technologies-logo-element {
      min-width: 65px;
    }
  }
}

.third-level-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;

  .first-for-grid-only {
    height: calc(12vh + 1px) !important; /* first row has different height */
  }
}

.thirdLevel-column-left {
  position: relative;
  width: 50%;
  /* margin-left: 50%; */
  height: 100vh;
  /* border-right: 1px solid;
  border-color: rgb(0, 0, 0, 0.15); */
  overflow-y: auto;
  padding-bottom: 80px;
  box-shadow: rgb(0 0 0 / 10%) 3px 0px 8px 0px;
  z-index:1;
  /* padding-right: 17px;
  box-sizing: content-box; */
}

.thirdLevel-scrollDown {
  z-index: 2;
  position: fixed;
  top: 90%;
  right: 30%;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  svg path {
    fill: #fff;
  }
}

.thirdLevel-scrollDown:hover {
  background: rgba(0, 0, 0, 0.7);
}


.thirdLevel-column-right {
  background: #f8f9fb;
  width: 50%;
  overflow-y: auto;
  position: relative;
  height: 100vh;
  box-shadow: rgb(0 0 0 / 10%) 3px 0px 8px 0px;
}

.third-level-nav-video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  /*transform: translate(-50%, -50%);*/
  width: 100%;
  height: 100%;
}


/* NAV BUTTON ANIMATION */
/*body {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    margin: 0;
}*/
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}


/* CONTACT FORM MODAL */

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.33);

  &.hidden {
    display: none;
  }
}


.contact-form-modal-link {
  white-space: nowrap;
  color: white;
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

.contact-form-modal-link:hover {
  color: black;
  text-decoration: none;
}

.modal-padding {
  padding: 40px;
}
@media screen and (max-width: 996px) {
  .modal-padding {
    padding: 20px;
    padding-right: 0;
  }
}

.contact-modal {
  position: absolute;
  min-height: 400px;
  width: 50%;
  max-width: 960px;
  margin-top: 100px;
  margin-left: calc(25%);
  background-color: white;
  box-shadow: 0px 10px 30px 15px rgb(0 0 0 / 50%);
}

.contact-modal-content {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-top: 40px;
}
@media screen and (max-width: 550px) {
  .contact-modal-content {
    margin-top: 2vh;
  }
}

.contact-modal-content div:first-child {
  /* width: calc(100% - 10px); */
  padding-right: 50px;
}

@media screen and (max-width: 1440px) {
  .contact-modal-content div:first-child {
    padding-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .contact-modal {
    width: 60%;
    margin-left: 20%;
  }
}

@media screen and (max-width: 768px) {
  .contact-modal {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 550px) {
  .contact-modal {
    width: 80%;
    margin-left: 10%;
    margin-top: 70px;
  }
  .contact-modal-content {
    flex-direction: column;
    row-gap: 20px;
  }
  .contact-modal-content:first-child, .contact-modal-content:nth-child(2) {
    width: calc(100% - 10px);
  }
}

.modal-close {
  cursor: pointer;
  float: right;
  margin-right: 17px;
  margin-top: 8px;
}

.modal-close:hover {
  text-decoration: none;
}


/* NAVIGATION STYLE (third-level) */

/* works, addedvalue, solutions */
.nav-works-container-left, .nav-works-container-right, .nav-addedvalue-container-left, .nav-addedvalue-container-right, .nav-solutions-container-right, .nav-solutions-container-left, .nav-company-container-right, .nav-company-container-left {
  padding: 2% 10%;
}

.nav-company-container-right {
  height: 100%;
}

.third-level-nav-name {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-30)/35px var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  /*font: normal normal normal 30px/60px Futura PT;
  letter-spacing: 0px;
  color: #000000;*/
  opacity: 1;
  margin-bottom: 20px;
}

.third-level-nav-teaserTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font-weight: bold;
  /*font: normal normal normal 20px/20px Futura PT;
  letter-spacing: 0px;
  color: #000000;*/
  opacity: 1;
  margin-top: 25px;
  margin-bottom: 10px;
}

.third-level-nav-teaserImage2 {
  margin-top: 10vh; /* 10px */
  margin-bottom: 25px;
  width: 100%;
  max-width: 400px;
  height: auto;
  display: flex;
  justify-content: center;
  transition: all 300ms ease-in;

  img {
    width: 100%;
  }
}

.third-level-nav-teaserText {
  font: normal normal 500 20px/25px var(--unnamed-font-family-futura-pt);
  font-weight: normal;
  letter-spacing: 0;
  color: var(--unnamed-color-000000);
  text-align: left;
  /*font: normal normal 300 18px/20px Futura PT;
  letter-spacing: 0px;
  color: #000000;*/
  opacity: 1;
  /*font-size: 16px;*/
}

.third-level-nav-title {
  margin-top: 20px;
  margin-bottom: 10px; /* 20px */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-futura-pt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font-weight: 500;
  /*font: normal normal normal 20px/20px Futura PT !important;
  letter-spacing: 0px;
  color: #000000;*/
  opacity: 1;
}

.third-level-nav-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-futura-pt) !important;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000) !important;
  text-align: left;
  /*font: normal normal 300 18px/20px Futura PT !important;
  letter-spacing: 0px;
  color: #000000;*/
  opacity: 1;
  /* font-size: 16px; */
}

/* company */

.nav-company-bg {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;

  img {
    width: 850px;
    transform: translate(105px, -105px);
    height: auto;
  }
}

.nav-company-container {
  padding: 380px 180px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30vh;
  z-index: 2;
}

.nav-company-title-content {
}

/* FAQ STYLE NAVIGATION */
.faq_nav {
  flex-direction: column !important;

  .faqs-column-1_nav, .faqs-column-2_nav {
    width: 100%;
  }
}

#firstLevelNav {
  min-width: 25vw;
}

.secondLevelNavContainer {
  min-width: 25vw;
}

@media screen and (max-width: 1440px) {
  #firstLevelNav {
    min-width: 32vw;
  }
  .secondLevelNavContainer {
    min-width: 25vw;
  }
  .thirdLevelNavContainer {
    animation: opacity 300ms ease-in-out;
    /* max-width: 45vw; */
  }
}

/* Mobile Nav */
@media screen and (max-width: 1200px) {
  #firstLevelNav_m {
    /* overflow-y: scroll */
    min-width: 100vw;
    width: 100vw;
  }
  .secondLevelNavContainer_m {
    position: absolute;
    width: 100vw;
    min-width: 365px;
    min-height: 100%;
  }
  .thirdLevelNavContainer_m {
    position: absolute;
    min-width: 100vw;
    width: 100vw;
  }
  .second-level-header-menu-li_root {
    background-color: #919CAE;
    padding-top: 8vh;
    padding-bottom: 5%;
    box-shadow: 5px 0 15px 1px rgb(0 0 0 / 10%);
  }
  .second-level-header-menu-a_root {
    border-top: none;
    margin-left: 20px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25) var(--unnamed-font-family-futura-pt);
    font-weight: 500;
    color: black;
    text-decoration: none;
    transition: color 300ms ease-in-out;
  }
  .second-level-header-menu-a_root:hover {
    color: white;
    text-decoration: none;
  }

  .header-menu-arrow-icon-a_root {
    margin-left: calc(5% + 20px);
    margin-bottom: 8px;
    text-decoration: none;
  }

  #navContainer_mobile .second-level-header-menu-ul {
    padding-top: 0;
  }
  #navContainer_mobile .second-level-header-menu-ul li:nth-child(2) {
    /* border-top: 1px solid rgba(0, 0, 0, 0.15); */
  }
  #navContainer_mobile .thirdLevel-column-left {
    width: 100%;
  }


  #navContainer_mobile .third-level-content {
    height: 50%;

    .third-level-logo-element {
      width: 220px;
      height: 110px;
    }
  }
  #navContainer_mobile .header-menu-ul {
    padding-left: 50px;
  }
  #navContainer_mobile .header-menu-ul.bottom-nav-element {
    margin-bottom: 50px;
  }

}

@media screen and (max-width: 628px) {
  #navContainer_mobile .third-level-content {
    height: 60%;
    margin-top: 30px;
  }
}

@media screen and (max-height: 900px) {
  .second-level-header-menu-li_root {
    padding-top: 8vh;
  }
  #navContainer_mobile .header-menu-ul {
    padding-top: 5vh;
  }
  #navContainer_mobile .header-menu-li {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  #navContainer_mobile .header-menu-li:first-child {
    padding-top: 3vh;
  }

  #navContainer_mobile .header-menu-ul.bottom-nav-element {
    padding-top: 5vh;
  }

  #navContainer_mobile {
    .second-level-header-menu-li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .second-level-header-menu-li:nth-child(2) {
      margin-top: 20px;
    }
  }
}

@media screen and (max-height: 650px) {
  .second-level-header-menu-li_root {
    padding-top: 10vh;
  }
  #navContainer_mobile .header-menu-ul {
    padding-top: 5vh;
  }
  #navContainer_mobile .header-menu-li {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  #navContainer_mobile .header-menu-ul.bottom-nav-element {
    padding-top: 6vh;
  }

  #navContainer_mobile {
    .second-level-header-menu-li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

#placeholder2ndLevel {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  margin-top: 50px;
}

.first-level-nav-logo-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 85px;
  width: 260px;
  .first-level-nav-logo {
    width: 40%;
    height: auto;
    &.footer-socials {
      display: flex;
      img {
        width: 35px;
      }
    }
    img {
      width:100%;
    }
    &.swissdot{
      margin-right: auto;
      margin-left: 10px;
    }
  }
  .footer-socials a:last-child {
    margin-left: 5px;
  }
}

@media screen and (max-width: 370px) {
  .first-level-nav-logo-container {
    // display: none;
  }
}

.teaser-bubble-text {
  z-index: 10;
}
.bubble-element {
  opacity: 0.85;
  position: absolute;
  width: 100% !important;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
}