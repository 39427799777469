.btn-ct {
  @extend .text-center;

  padding: 9px 17px;
  width: 100%;
  position: relative;
  display: inline-block;
  color: $white;
  background-color: $theme-normal-text;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 12px;
  text-decoration: none !important;

  &:hover {
    color: $theme-active;
  }

  div {
    @extend .position-relative;

    .arrow {
      @extend .position-absolute;
      @extend .m-auto;
      @extend .overflow-auto;
      top: 0;
      bottom: 0;
      right: 0;
    }

    > {
      div {
        &.title {
          font-size: 20px;
          padding-right: 20px;
          text-align: left;
          display: inline-block;
          word-break: break-word;
        }

        &.text {
          font-size: 1rem;
        }

        &:nth-child(2) {
          padding-top: 0.5rem;
        }
      }

      .title + .arrow {
        &:nth-child(2) {
          @extend .h-100;
          font-size: 20px;
        }
      }

      .text + .arrow {
        &:nth-child(3) {
          @extend .h-50;
          font-size: 23px;
        }

        &:nth-child(2) {
          @extend .h-100;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .btn-ct {

    div {
      > {
        div {
          &.title {
            font-size: 14px;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .btn-ct {
    div {
      > {
        div {
          &.title {
            /* font-size: 16px; */
          }
        }
      }
    }
  }
  .company-content-blocks {
    div {
      padding-right: 15px;
    }
  }
}
