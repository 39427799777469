.content-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
}

.content-collapse i {
  float: right;
}

.content-collapse[aria-expanded="true"] i::before {
  content: '\e804';
}
