.none-list-style {
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
