// Overwrite bootstrap defaults in
// node_modules/bootstrap/scss/_variables.scss

@import "../common/theme_colors";
$enable-rounded: false;

.btn {
  margin: 5px !important;
}

$font-family-sans-serif:      'Roboto', sans-serif;
$font-family-monospace:       'Roboto', monospace;

$link-color:                              $theme-link-text;
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline;

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$h1-font-size:                30px;
$h2-font-size:                26px;
$h3-font-size:                22px;
$h4-font-size:                18px;
$h5-font-size:                18px;
$h6-font-size:                $font-size-base;

$body-bg:                   $theme-background;
$body-color:                $theme-normal-text;

$breadcrumb-bg:                     $theme-background;

$breadcrumb-padding-x:              0;
$breadcrumb-divider-color:          $theme-normal-text;
$breadcrumb-active-color:           $theme-active;
$breadcrumb-item-padding:           0.3rem;
