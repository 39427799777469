/* * {
  outline: 1px solid red;
  outline-offset: -1px;
} */

#footer {
  position: relative;
}

.footer-container {
  margin-top: 5vh;
}

.copyright {
  user-select: none;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25) var(--unnamed-font-family-futura-pt);
  display: inline;
  font-size: 16px;
  color: $white;
  opacity: 0.9;
  position: absolute;
  margin-top: -25px !important;
  transform: translateY(33px);

  .copyright-name {
    font-family: 'Times New Roman', serif;
    font-size: 20px;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    cursor: default;
  }
}

#copyright_mobile {
  display: flex;
  flex-direction: column;
}

#btn-footer-blog, #btn-footer-more {
  background: transparent;
}

#btn-footer-blog:hover {
  background: #D1D10F !important;
  border: none !important;
}

#btn-footer-more:hover {
  background: #D1D10F !important;
  border: none !important;
}

.call-to-action-btn{
  user-select: none;
}

.call-to-action-text:hover, .call-to-action-btn:hover {
  color: white;
}

.bottom-line {
  padding-bottom: 2vh;

  .links {
    display: flex;
    flex-direction: row;
    user-select: none;
    float: right;
    column-gap: 50px;
    width: 400px;
    position: absolute;
    right: 10vw;
    bottom: 65px;

    a {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25) var(--unnamed-font-family-futura-pt);
      font-size: 16px;
      text-decoration: none;
      color: white;
      text-align: right;
    }

    a:hover {
      color: #D1D10F;
    }
  }

  .float-right {
    float: right;
    margin-right: 5vw;

    .links {
      display: flex;
      flex-direction: row;

      a {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-25) var(--unnamed-font-family-futura-pt);
        font-size: 16px;
        text-decoration: none;
        color: white;
        margin-right: 50px;
      }

      a:hover {
        color: #D1D10F;
      }
    }
  }

  .swissmadesoftware {
    position: absolute;
    background: white;
    border-radius: 8px 8px 0 0;
    bottom: 102px;
    width: 120px;
    height: 80px;
    user-select: none;

    img {
      width: 80px;
      margin-left: 15%;
      margin-top: 10%;
      height: auto;
      pointer-events: none;
    }

    &.swissdot {
      margin-left: 133px;
    }
  }

  @media screen and (max-width: 768px) {
    .swissmadesoftware{
      left: 5vw !important;

      &.swissdot {
        margin-left: 95px;
      }
    }
  }

  .swissmadesoftware:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  .faq-banner-container{
    margin-right: 5vw;
    float: right;
    .links{
      column-gap: 50px;
      padding-left: 55px;
      .link{
        margin: 0 !important;
      }
    }
  }

  @media screen and (max-width: 996px){
    .faq-banner-container{
      margin-right: 10%;
    }
    .copyright_mobile{
      .links{
        margin-right: 25px !important;
      }
    }
  }

  /*@media screen and (max-width: 768px){
    .faq-banner-container {
      .links{
        flex-direction: column;
      }
    }
  }*/

  .faq-banner {
    position: absolute;
    background: white;
    bottom: 102px;
    width: 400px;
    height: 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    user-select: none;
    right: 10vw;

    p {
      margin-top: 12px;
      margin-left: 55px;
      color: black;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-futura-pt);
      font-size: 18px;
      .mobile{
        display: none;
      }
    }

    p:hover {
      color: #D1D10F;
      cursor: pointer;
    }

    svg {
      position: absolute;
      margin-right: 20px;
      width: 45px;
      height: 70px;
      bottom: 5px;
    }
  }
}

@media screen and (max-width: 1350px){
  .faq-banner-container{
    .links{
      width: 320px;
      padding-left: 0 !important;
    }
  }
}
@media screen and (max-width: 996px){
  .faq-banner-container{
    .links{
      width: 290px;
      padding-left: 0 !important;
    }
  }
}
@media screen and (max-width: 768px){
  .faq-banner{
     right: 5vw !important;
  }
  .bottom-line{
    .links{
      right: 5vw !important;
    }
  }
}
@media screen and (max-width: 595px){
  .faq-banner-container{
    .links{
      width: 170px;
      padding-left: 0 !important;
      column-gap: 15px !important;
    }
  }
  .bottom-line{
    .links{
      right: 50px !important;
    }
  }
}

.footer-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  user-select: none;

  .column-solutions, .column-services, .column-services2, .column-company {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-right: 2.5vw; /* 3vw */

    .title {
      margin-bottom: 0;
      transform: translateY(-13px);
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-30) var(--unnamed-font-family-futura-pt);
      font-size: 26px;
      line-height: 26px;
    }
  }

  .column-solutions {
    margin-left: 5vw; /* 10vw */
    min-width: 220px;
  }

  .column-company {
    min-width: 150px;
  }

  .column-services {
    max-width: 10vw;
    min-width: 200px;

    .services-text {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) var(--unnamed-font-family-futura-pt);
      /* margin-top: -10px; */
      font-size: 18px;
    }
  }

  div a {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) var(--unnamed-font-family-futura-pt);
    font-size: 18px;
    font-weight: 300;
    color: white;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;

  }

  div a:hover {
    color: #D1D10F;
  }
}


footer {
  background: #1C1B1B;
  color: $theme-inverted-text;

  & > *:not(hr) {
    padding-bottom: 6vh;
  }

  .footer-content {
    /* center */
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .footer-container {
    margin-bottom: 9rem;
    display: flex;
    flex-direction: row;

    .company-address {
      .company-name {
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: bold 35px/41px "Times New Roman", serif;

        .copyright-r {
          font: 8px/8px "Arial", sans-serif;
          position: fixed;
          margin-top: 6px;
          margin-left: 1px;
        }
      }

      .address-element, .address-mailto {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/22px var(--unnamed-font-family-futura-pt);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: white;
        text-align: left;
        font-size: 18px
      }

      .container-logo {
        svg {
          width: 80%; /* 90% */
          max-width: 320px;
        }
      }


      .address-mailto:hover {
        text-decoration: none;
        color: #D1D10F;
      }

      .footer-socials {
        padding-top: 28px;

        & > a {
          display: inline-block;
          padding-right: 17px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        & > a:hover {
          opacity: 0.9;
        }
      }

    }
  }
}

#footer_line {
  width: calc(100% - 5px);
  border: 1px dashed;
  position: absolute;
  bottom: 86px;
  margin-bottom: 15px;
}

@media screen and (max-width: 1350px) {
  #footer_line {
    width: calc(100% - 5px);
    border: 1px dashed;
  }
  footer {

    .footer-container {
      flex-direction: column;
    }
  }
  .footer-links {
    margin-top: 5%;
    justify-content: space-between;

    .column-solutions {
      margin-left: 0;
    }

    .column-services {
      max-width: 30%;
    }
  }
  /* .bottom-line div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;

    .links {
      margin-top: 10px;
    }
  }
   */
  .faq-banner {
    transform: translateX(-3px);
    width: 350px !important;
    right: 10vw;
  }

}

@media print {
  #footer_line {
    width: calc(100% - 5px);
    border: 1px dashed;
  }
  footer {

    .footer-container {
      flex-direction: column;
    }
  }
  .footer-links {
    margin-top: 5%;
    justify-content: space-between;

    .column-solutions {
      margin-left: 0;
    }

    .column-services {
      max-width: 30%;
    }
  }
  /* .bottom-line div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;

    .links {
      margin-top: 10px;
    }
  }
   */
  .faq-banner {
    transform: translateX(-3px);
    width: 350px !important;
  }

}

@media screen and (max-width: 996px) {
  .footer-links {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;

    .column-services, .column-company {
      /* margin-top: 30px; */
      width: 45%;
    }

    .column-services2, .column-company {
      margin-top: 30px;
    }

    .column-solutions, .column-services2 {
      width: 35%;
    }

    .column-services {
      max-width: 50%;
    }
  }
  .bottom-line {
    .swissmadesoftware {
      border-radius: 6px 6px 0 0;
      width: 80px;
      height: 55px;
      left: 10vw;

      img {
        width: 55px;
        margin-left: 15%;
        margin-top: 10%;
      }

      &.swissdot{
        margin-left: 95px;
      }
    }
  }
  .faq-banner {
    height: 40px !important;
    width: 300px !important;
    right: 5vw;

    p {
      font-size: 16px !important;
      margin-left: 40px !important;
      margin-top: 7px !important;
    }

    svg {
      width: 30px !important;
      height: 60px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-links {
    flex-direction: column;

    .column-solutions, .column-services, .column-services2, .column-company {
      margin-top: 25px;
      width: 100%;
      max-width: 100%;
    }

    .column-services, .column-services2 {
      margin-top: 50px;
    }

    .column-company {
      margin-top: 40px;
    }
  }
  .bottom-line {
    .links a {
      font-size: 16px !important;
    }

    .copyright {
      font-size: 16px;
    }

    .copyright-name {
      font-size: 16px;
    }
  }

  .company-address {
    .container-logo {
      svg {
        max-width: 375px !important;
      }
    }
  }
}

@media screen and (max-width: 595px) {
  footer {

    .footer-links {
      .column-services {
        max-width: 95%; /* 80% */
      }
    }

    .footer-container {
      .company-address {
        .company-name {
          font-size: 25px;
          line-height: 35px;
        }

        .address-element {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }


  }
  .faq-banner {
    height: 35px !important;
    width: 135px !important;

    p {
      font-size: 15px !important;
      margin-left: 30px !important;
      margin-top: 5px !important;
      .desktop{
        display: none;
      }
      .mobile{
        display: unset !important;
      }
    }

    svg {
      width: 25px !important;
      height: 50px !important;
    }
  }
}

@media screen and (max-width: 388px) {
  .bottom-line {

    .faq-banner {
      /*width: 220px !important;*/

      p {
        font-size: 10px !important;
        margin-left: 25px !important;
        margin-top: 5px !important;
      }

      svg {
        width: 25px !important;
        height: 50px !important;
      }
    }
  }
}
.mw-365{
  min-width: 365px !important;
}
header{
  min-width: 365px !important;
}
body{
  min-width: 365px !important;
}
.content{
  min-width: 365px !important;
}
.call-to-action{
  min-width: 365px !important;
}
footer{
  min-width: 365px !important;
}
.background-wave{
  min-width: 365px !important;
}

@media screen and (max-width: 355px) {
  .copyright {
    //transform: translateY(10px);
  }
  .faq-banner {
    /*width: 180px !important;*/

    p {
      margin-left: 18px !important;
      margin-top: 5px !important;
    }

    svg {
      width: 25px !important;
      height: 50px !important;
    }
  }

  .bottom-line .float-right .links a {
    font-size: 12px !important;
    margin-right: 10px !important;
  }

  .bottom-line .copyright {
    font-size: 12px !important;

    .copyright-name {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 321px) {
  .footer-container {
    .company-address {
      .company-name {
        font-size: 20px !important;
      }
    }
  }
}

/**************/
/*** GLOBAL ***/
/**************/
.footer-container {
  padding: 0 5%;
}

.bottom-line {
  padding: 0 5%;
}

@media screen and (min-width: 1750px) {
  .bottom-line {
    padding: 0 5%;
  }
  footer {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  bottom-line {
    padding: 0 0;
  }
}