/*. * {
  outline: 1px solid red;
   outline-offset: -1px;
} */

.solutions {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 100%;

  .solution-images {
    width: 55%;
    /* scroll slider */
    /* overflow:scroll;
    max-height: 600px; */

    /*margin-right: 50px;*/
  }

  @-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }


  .solution-image-set {
    /* max-width: 700px; */
    max-width: 100%;
    display: none;
    /* scroll slider */
    /* margin-bottom: 200px; */

    &.active {
      display: block;

      .solution-image {
        -webkit-animation: fadeInFromNone 1s ease-in-out;
        -moz-animation: fadeInFromNone 1s ease-in-out;
        -o-animation: fadeInFromNone 1s ease-in-out;
        animation: fadeInFromNone 1s ease-in-out;
      }
    }

    img {
      width: 100%;
    }

    .solution-image-bubble {
      height: 100%;
      position: relative;
      /*margin-top: 100px;*/
    }

    .solution-image-bubble-overlay {
      /* background-image: url('data:image/svg+xml;utf8, <svg id="uuid-1706330a-3448-4c1c-8fa1-750434259e5c" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 721.06"><defs><style>.uuid-d063d188-2f88-45e1-83fa-ef48fd223f7c{fill:white;}</style></defs><path class="uuid-d063d188-2f88-45e1-83fa-ef48fd223f7c" d="M4.92,0V721.06H998.08V0H4.92ZM844.47,455.74c-21.98,20.96-48.97,1.88-102.45-3.23-131.56-12.55-181.35,82.58-294.09,100.77-148.85,24.03-318.7-100.43-315.52-200.41,2.42-75.89,104.45-132.57,168.55-168.18,121.57-67.51,377.22-155.74,498.87-8.03,70.04,85.01,87.44,238.25,44.65,279.08Z"/></svg>'); */
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: cover;
      position: absolute;
      height: 101%;
      width: 101%;
      transform: scale(1.15);
    }

    .solution-image {
      width: 60%;
      height: auto;
      position: absolute;
      z-index: 3;
      right: -10%;
      bottom: -20%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .solution-image-alone {
    position: relative;
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .solution-links {
    width: 40%;
    height: fit-content;
    position: relative;
    margin-left: 15%;
    padding: 2em 0 2em 35px;
    border-left: dashed 1px;

    .solution-link {
      position: relative;
      display: block;
      margin-bottom: 5px;
      text-decoration: none;

      .solution-learn-more {
        display: none;
        cursor: pointer;
      }


      &.active {
        text-decoration: none;

        .solution-name {
          margin-left: 2px;
          color: var(--unnamed-color-d1d10f);
          -webkit-transition: margin-left 0.3s ease-in-out;
          transition: margin-left 0.3s ease-in-out;
        }

        .solution-learn-more {
          text-decoration: underline;
          display: initial;
          position: absolute;
        }

        .solution-bullet {
          transform: scale(1.4);
          background: var(--unnamed-color-d1d10f) 0 0% no-repeat padding-box;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

      }

      .solution-teaser-title {
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
      }

      .solution-name {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-25)/30px var(--unnamed-font-family-futura-pt);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
      }

      .solution-name:hover {
        cursor: pointer;
        text-decoration: none;
      }

      .solution-bullet {
        background: var(--unnamed-color-000000) 0 0% no-repeat padding-box;;
        width: 18px;
        height: 18px;
        border-radius: 24px;
        position: absolute;
        left: calc(-2em - 12px);
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;

      }

    }

    h2 {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-30)/36px var(--unnamed-font-family-futura-pt);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-000000);
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, calc(-100% - .5em));
    }
  }

  @media screen and (min-width: 768px) {
    .solution-links {
      margin-top: 60px;
    }
  }

}


@media screen and (max-width: 1920px) {
  .solutions {
    .solution-images {
      max-width: 50%;
    }

    .solution-image-set {
      max-width: 100%;
      display: none;
    }
  }
}

@media screen and (max-width: 1440px) {
  .solutions {

    .solution-image-set {
      max-width: 100%;
      display: none;
    }
  }
}

@media screen and (max-width: 996px) {
  .solutions {

    .solution-image-set {
      max-width: 100%;
      display: none;
    }

    .solution-links {
      .solution-link {
        .solution-name {
          font-size: var(--unnamed-font-size-20);
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {
  body .page-header {
    margin-bottom: 0;
  }
  .content {
    margin-top: 0;
  }
  .solutions {
    flex-direction: column;
    margin-top: 0;

    .solution-links {
      width: 85%;

      h2 {
        position: absolute;
        transform: translate(-5px, -55px);
      }
    }

    .solution-images {
      width: 80%;
      max-width: 80%;
      margin: 0;
      margin-bottom: 20px;
      padding-bottom: 1em;
    }

    .solution-image-set {
      margin-top: 50px;
      margin-bottom: 110px;
      width: 100%;
      max-width: 100%;
      display: none;
    }
  }
}


@media screen and (max-width: 550px) {
  .solutions {

    .solution-links {

      .solution-link {
        margin-bottom: -10px;
        .solution-name {
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }

  }
}


.technologies {

  & > h2 {
    font: var(--unnamed-font-style-normal) normal 900 var(--unnamed-font-size-35)/43px var(--unnamed-font-family-futura-pt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;
    padding-top: 3em;
    padding-bottom: 1.5em;
  }

  & > p {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-30)/36px var(--unnamed-font-family-futura-pt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;
    padding-bottom: 1em;
  }

  .cards {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    max-height: 1500px;
    align-items: center;
    max-width: calc(1200px + 4em);
    margin: 0 auto;

    background: transparent radial-gradient(closest-side at 50% 50%, var(--unnamed-color-ffffff) 0%, #E6E6E6 56%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;

    .service-card {
      max-width: 400px;
      margin: 10px;
      margin-bottom: 1em;
      border-radius: 4px;

      &:not(.no-background) {
        background: var(--unnamed-color-ffffff) 0 0% no-repeat padding-box;
        box-shadow: 0 2px 4px #00000029;
      }

      &:nth-child(1) {
        background: var(--unnamed-color-f8f9fb) 0 0% no-repeat padding-box;
      }

      &:nth-child(1), &:nth-child(7) {
        margin-top: 80px;
      }

      h3 {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) var(--unnamed-font-family-futura-pt);
        font-weight: 900;
        font-size: 20px;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: center;
        padding: 1em 0;
        margin: 0;
      }

      p {
        width: calc(100% - 1em);
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-futura-pt);
        font-size: 18px;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        margin: 1.2em auto;
      }

      img {
        width: calc(100% - 1em);
        margin: 0 auto;
        border-radius: 4px 4px 0 0;
        display: block;
      }
    }
  }
}

#service-text, #service-title {
  text-align: center;
}

@media screen and (max-width: 1556px) {
  .technologies {
    .technologies-teaser {

      .teaser-title {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .cards {
      justify-content: center;
      flex-flow: row wrap;
      max-height: fit-content;

      .service-card {
        /* margin: 0; */
        margin-right: 1em;
      }

      .service-card:nth-child(1), .service-card:nth-child(7) {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 927px) {
  .technologies {
    .cards {

      background: transparent radial-gradient(closest-side at 50% 35%, var(--unnamed-color-ffffff) 0%, #E6E6E6 16%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;

      .service-card:nth-child(1) {
        margin-top: 0;
      }

      .service-card, .service-card:nth-child(7) {
        margin-top: 2em;
        margin-right: 1em;
        margin-left: 1em;
      }

    }
  }
}

@media screen and (max-width: 996px) {
  .technologies {
    .technologies-teaser {
      padding: 0;
    }

    .cards {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .technologies {
    .technologies-teaser {
      margin-top: 50px;
      margin-bottom: 50px;

      .teaser-title {
        font-size: 20px;
        line-height: 25px;
      }

      .teaser-text {
        font-size: 16px;
      }
    }
  }
}


.technologies-teaser {
  text-align: center !important;
  width: 100%;
  margin-bottom: 80px;
}

@media screen and (max-width: 996px) {
  .technologies-teaser {
    margin-top: 80px;
  }
}

.teaser-title {
  font: var(--unnamed-font-style-normal) normal 900 30px/35px var(--unnamed-font-family-futura-pt);
  line-height: 37px;
  letter-spacing: 0;
  color: black;
}

.teaser-text {
  font-size: 16px;
}

.teaser-text:hover {
  text-decoration: none;
}

@media screen and (max-width: 550px) {
  .teaser-title{
    line-height: 35px;
    font-size: 25px;
  }
}

/* services */
@media screen and (max-width: 350px) {
  .service2 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .row.customer-index {
    margin-top: 20px !important;
  }
}

/* call-to-action */
.call-to-action {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  transform: translateY(5px);
  /*background-image: url(./svg/call-to-action.svg);
  background-repeat: no-repeat;
  background-position: center;*/
}

.call-to-action-background-wave {
  /*transform: rotate(180deg);*/
  position: absolute;
  width: 100%;
  transform: translateY(3px);
  bottom: 0;
  z-index: -1;
}

.call-to-action-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.call-to-action-left {
  margin-bottom: 55px;
  width: 45%;
  margin-right: -5%;
  margin-left: 7%;
  position: relative;

  .call-to-action-img {
    width: 90%;
    margin-left: 5%;
  }

  .call-to-action-img2 {
    width: 55%;
    margin-left: 27%;
    margin-top: 10%;
  }
}

.call-to-action-right {
  width: 50%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  margin-top: 5vw;

  .call-to-action-title {
    font-size: 30px;
  }

  .call-to-action-title:hover {
    color: white;
  }

  .call-to-action-text {
    font-size: 20px;
  }

}


@media screen and (min-width: 1920px) {
  .call-to-action-background-wave {
    min-height: 140%;
  }
  .call-to-action-right {
    margin-top: 8vw;

    .call-to-action-title {
      font-size: 35px;
    }

    .call-to-action-text {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .call-to-action-background-wave {
    height: 130%;
  }
  .call-to-action-right {
    margin-top: 2vw;
    width: 55%;
    margin-bottom: 5vw;

    .call-to-action-title {
      font-size: 28px;
    }

    .call-to-action-text {
      font-size: 17px;
    }

  }
  .call-to-action-left {
    width: 40%;
  }
}

@media screen and (max-width: 996px) {
  .call-to-action-container {
    flex-direction: column;

    .call-to-action-left {
      width: 100%;
      margin: -16px;
      padding: 0 5%;

      .call-to-action-img {
        width: 85%;
      }

      .call-to-action-img2 {
        width: 55%;
        margin-left: 30%;
        margin-top: 15%;
      }
    }

    .call-to-action-right {
      margin-top: 5vw;
      width: 100%;
      padding: 0 5%;
      text-align: center;
      margin-bottom: 5vw;

      .call-to-action-title {
        font-size: 25px;
      }

      .call-to-action-text {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .call-to-action-container {

    .call-to-action-right {
      .call-to-action-title {
        margin-top: 40px;
        font-size: 25px;
      }

      .call-to-action-text {
        font-size: 15px;
      }
    }


  }
}

@media (min-width: 768px) and (max-width: 996px) {
  .call-to-action-container {
    .call-to-action-left {
      .call-to-action-img {
        width: 75%;
      }
      .call-to-action-img2 {
        width: 50%;
        margin-left: 25%;
      }
    }
  }
}

.call-to-action-img {
  width: 100%;
  margin-left: 10%;
  position: absolute;
  z-index: -1;
}

.call-to-action-img2 {
  width: 60%;
  margin-left: 30%;
  margin-top: 10%;
}

.call-to-action-title {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  color: white;
  padding-bottom: 50px;
}

.call-to-action-title:hover {
  text-decoration: none;
}

.call-to-action-text {
  color: white;
}

.call-to-action-text:hover {
  text-decoration: none;
}

.call-to-action-btn {
  cursor: pointer;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  width: fit-content;
  padding: 5px 25px;
  font-size: 12px;
  align-self: center;
  margin-top: 50px;
  height: 40px;
  line-height: 30px;
  transition: background-color 200ms ease-in-out;
}

.call-to-action-btn:hover {
  background: #d1d10f;
  border: none;
  text-decoration: none;
}


.slider-teaser-text {
  margin-top: 90px;
}

.swiper {
  width: 600px;
  height: 300px;
}

