@font-face {
  font-family: 'pcard-icon';
  src: url('main/fonts/pcard-icon.eot?35503472');
  src: url('main/fonts/pcard-icon.eot?35503472#iefix') format('embedded-opentype'),
  url('main/fonts/pcard-icon.woff2?35503472') format('woff2'),
  url('main/fonts/pcard-icon.woff?35503472') format('woff'),
  url('main/fonts/pcard-icon.ttf?35503472') format('truetype'),
  url('main/fonts/pcard-icon.svg?35503472#pcard-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "pcard-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-l:before { content: '\e802'; } /* '' */
.icon-arrow-r:before { content: '\e803'; } /* '' */
.icon-arrow-up:before { content: '\e804'; } /* '' */
.icon-close:before { content: '\e805'; } /* '' */
.icon-ok-circled:before { content: '\e815'; } /* '' */

@import url("https://use.typekit.net/hvh3dpy.css");
@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@500&display=swap');


:root {
  /* Font/text values */
  --unnamed-font-family-futura-pt: futura-pt;
  --unnamed-font-family-felttiproman: felt-tip-roman;
  --unnamed-font-family-rokkitt: Rokkitt;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-normal: 400;
  --unnamed-font-weight-medium: 500;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-25: 25px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-32: 32px;
  --unnamed-font-size-35: 35px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-35: 35px;
  --unnamed-line-spacing-40: 40px;
  --unnamed-line-spacing-45: 45px;
  --unnamed-line-spacing-50: 50px;
  --unnamed-line-spacing-70: 70px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}
.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-35);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}
.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-felttiproman);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-1d1d1b);
}
.unnamed-character-style-4 {
  font-family: var(--unnamed-font-family-felttiproman);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-50);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-1d1d1b);
}
.unnamed-character-style-5 {
  font-family: var(--unnamed-font-family-rokkitt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-45);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}
.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-300);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}
.unnamed-character-style-7 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}
.unnamed-character-style-8 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-70);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}
.unnamed-character-style-9 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}
.unnamed-character-style-10 {
  font-family: var(--unnamed-font-family-futura-pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}