@import "main/custom_bootstrap";
@import "~bootstrap/scss/bootstrap";
@import url("~parsleyjs/src/parsley.css");
@import url("https://use.typekit.net/hvh3dpy.css");

.logo_main {
}

// slick
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "~magnific-popup/dist/magnific-popup.css";
@import "main/fonts";
@import "main/icons";
@import "common/pcard_defaults";
@import "common/gallery";

/**
Classes with one reason to help for styling
 */
@import "main/helper_classes";

/**
Content Type Styles
*/
.content-block-wrapper {
  display: flow-root;
  padding-bottom: 15px;
  padding-right: 20px;
}

@import "main/timeline";

// divider
@import "main/modules/content/content_types/divider/divider";

// Image gallery
@import "main/modules/content/content_types/image_gallery/image_gallery";

// Text editor
@import "main/modules/content/content_types/text_editor/text_editor";

// Button same look main and admin
@import "common/button";
@import "main/layout";
@import "main/breadcrumb";
@import "main/footer";
@import "main/social";
@import "main/header";
@import "main/language_selector";
@import "main/text";
@import "main/scroll";
@import "main/hyphens";
@import "main/collapse";
@import "main/typography";

@import "main/modules/static_sites/homepage";