$timline-padding-between: 30px;
$timline-padding-horizontal: 20px;
$timeline-padding-right: 20px;
$timeline-line: 8px;
$point-size: 16px;

.timeline {
  position: relative;
  .line {
    background-color: #2c2c2c;
    width: $timeline-line;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(50% - #{$timeline-line / 2});
  }
  .timeline-item-card {
    @extend .shadow;
    background-color: white;
    margin: 0 0 $timline-padding-between;
    padding: 14px;
    position: relative;
    .timeline-image {
      width: 100%;
      padding: 15px 0;
    }
  }
  .timeline-item-year {
    @extend .shadow;
    background-color: white;
    width: 150px;
    padding: 14px;
    margin: 0 auto $timline-padding-between;
    text-align: center;
    clear: both;
  }
}

@include media-breakpoint-up(md) {
  .timeline {
    .timeline-item-card {
      width: calc(50% - 4px - #{$timline-padding-horizontal});
      &.left {
        float: left;
        clear: left;
        .timeline-arrow {
          border-left: 0.5rem solid #fff;
          right: -0.5rem;
        }

        .timeline-point {
          right: - $timline-padding-horizontal - $timeline-line - ($point-size / 2 - $timeline-line / 2);
        }
      }
      &.right {
        margin-top: $timeline-padding-right;
        float: right;
        clear: right;
        .timeline-arrow {
          border-right: 0.5rem solid #fff;
          left: -0.5rem;
        }

        .timeline-point {
          left: -$timline-padding-horizontal - $timeline-line - ($point-size / 2 - $timeline-line / 2);
        }
      }
      .timeline-arrow {
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        display: block;
        position: absolute;
        top: 0.5rem;
      }
      .timeline-point {
        border: $point-size / 4 solid white;
        width: $point-size;
        height: $point-size;
        display: block;
        position: absolute;
        top: 0.5rem;
        background-color: grey;
      }
    }
    .end {
      clear: both;
    }
  }
}
